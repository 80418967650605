import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { HiPencilAlt } from "react-icons/hi";
import profilepic from "../../components/photos/dp.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { CgCloseR } from "react-icons/cg";
import { BiTimeFive } from "react-icons/bi";
import axios from "axios";
import { FaUser, FaUserTie } from "react-icons/fa";
import event_img from "../../components/photos/dp.jpg";
import { BsCalendar2Date, BsArrowLeftRight } from "react-icons/bs";
import { AiFillCar, AiOutlineUser } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import {
  MdOutlineFlight,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
  MdOutlineCall,
  MdFlight,
} from "react-icons/md";

const Guest_event_detail = () => {
  const [editInput, setEditInput] = useState(true);
  const [show_submit_btn, set_Show_submit_btn] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const name = location.state.name;
  // console.log("name=", name.upload_agenda);

  useEffect(() => {
    post_event_id_flight();
    post_event_id_cab();
    post_event_id_assistance();
  }, []);

  // get_assistance api
  const [assistance, set_assistance] = useState();
  async function post_event_id_assistance() {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/getalldatafromassign.php", {
          assign_id: name.id,
          status: "assistant",
          user_id: name.user_id,
        })
        .then((response) => {
          console.log("get_assistance", response.data.data);
          // chnage key name here
          if (response.data.status == true) {
            set_assistance(response.data.data);
          } else {
            // alert("No Assistance Found");
          }
        });
    } catch (e) {}
  }
  // get_assistance api
  // get_incomingcab api
  const [incoming_cab, setincoming_cab] = useState("");
  const [outgoing_cab, setoutgoing_cab] = useState("");
  async function post_event_id_cab() {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/getalldatafromassign.php", {
            assign_id: name.id,
            status: "cab",
            user_id: name.user_id,
        })
        .then((response) => {
          console.log("get_incomingcab", response.data.Hotel_ja_rha_hai);
          if (response.data.status == true) {
            setincoming_cab(response.data.from_airport_to_events);
            setoutgoing_cab(response.data.from_event_to_airport);
          } else {
            // alert("No Cab");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // get_incomingcab api

  // get_incomingflight api
  const [incoming_Flight, setincoming_Flight] = useState("");
  const [outgoing_Flight, setoutgoing_Flight] = useState("");
  async function post_event_id_flight() {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/getalldatafromassign.php", {
            assign_id: name.id,
            status: "flight",
            user_id: name.user_id,
        })
        .then((response) => {
          console.log("get_incomingflight", response.data);
          if (response.data.status == true) {
            setincoming_Flight(response.data.thailand_ja_rha_hai);
            setoutgoing_Flight(response.data.thailand_se_aa__rha_hai);
          } else {
            // alert("No Flight");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // get_incomingflight api

  const signUpSchema = Yup.object().shape({
    ename: Yup.string().min(1).required("Enter Event Name"),
    startdate: Yup.date().required("Enter Start Date"),
    enddate: Yup.date().required("Enter End Date"),
    starttime: Yup.string().required("Enter Start Time"),
    endtime: Yup.string().required("Enter End Time"),
    venuename: Yup.string().min(1).required("Enter Venue Name"),
    venuelink: Yup.string().min(1).required("Enter Venue Link"),
    agenda: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
      if (file) {
        return file.size <= 2000000;
      } else {
        return true;
      }
    }),
    vaddress: Yup.string().min(1).required("Enter Venue Address"),
  });

  const [initialValues, setinitialValues] = useState({
    eimage: name.event_image,
    ename: name.events_name,
    startdate: name.events_start_date,
    enddate: name.events_end_date,
    starttime: name.event_start_time,
    endtime: name.event_end_time,
    edesicption: name.description,
    venuename: name.venue_name,
    venuelink: name.venue_link,
    vimage: name.venue_image,
    agenda: "",
    latitude: name.latitude,
    longitude: name.longitude,
    vaddress: name.venue_address,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      event_form(values);
      alert("form Submitted");
      // action.resetForm();
    },
  });

  // event api
  async function event_form(e) {
    // console.log("agenda =", e.agenda);

    var dataa = new FormData();
    dataa.append("id", name.id);
    dataa.append("agendaphoto", e.agenda);
    dataa.append("events_name", e.ename);
    dataa.append("description", e.edesicption);
    dataa.append("event_start_time", e.starttime);
    dataa.append("events_start_date", e.startdate);
    dataa.append("event_end_time", e.endtime);
    dataa.append("events_end_date", e.enddate);
    dataa.append("venue_name", e.venuename);
    dataa.append("venue_link", e.venuelink);
    dataa.append("latitude", e.latitude);
    dataa.append("longitude", e.longitude);
    dataa.append("venue_address", e.vaddress);
    console.log(dataa);
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/updateevents.php", dataa)
        .then((response) => {
          console.log("update events", response);
          if (response.data.status == true) {
            alert("Data uploaded successfully");
          } else {
            alert("File not upload");
          }
        });
    } catch (e) {
      console.log(e);
      alert("File not upload");
    }
  }
  // event api

  // pop up
  const [send_notifications, set_Send_notifications] = useState(false);
  const [Tittle, set_Tittle] = useState("");
  const [body, set_body] = useState("");

  async function handle_send_notification() {
    if (Tittle == "" || Tittle == undefined) {
      alert("Please give title to your message");
      return;
    }
    if (body == "" || body == undefined) {
      alert("Please give message body");
      return;
    }
    if (name.id == "" || name.id == undefined) {
      alert("No event id found");
      return;
    }

    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/manuallynotification.php", {
          event: name.id,
          body: body,
          title: Tittle,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data.status == true) {
            alert("Successfully Sent");
            set_Send_notifications(!send_notifications);
            set_Tittle("");
            set_body("");
          } else {
            alert(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // pop up

  return (
    <>
      <div className="main-container">
        <MainSidebar />
        <div className="allcontent">
          <Navbar />
          {/* <Breadcrumbs/> */}
          <div className="mainbox">
            {/* pop up for send notification*/}
            {send_notifications && (
              <div className="modal">
                <div
                  onClick={() => {
                    set_Send_notifications(!send_notifications);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Send</h2>
                    <button
                      className="close-modal"
                      onClick={() => {
                        set_Send_notifications(!send_notifications);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <div style={{ minHeight: "380px" }} className="modal-body">
                    <label
                      style={{ marginTop: "20px" }}
                      htmlFor="ename"
                      className="input-label"
                    >
                      Tittle <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="name"
                      className="custominput"
                      placeholder="Tittle"
                      onChange={(e) => {
                        set_Tittle(e.target.value);
                      }}
                    />

                    <div className="input-block">
                      <label htmlFor="edesicption" className="input-label">
                        Body <span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        style={{ height: "110px" }}
                        className="custominput"
                        onChange={(e) => {
                          set_body(e.target.value);
                        }}
                        placeholder="Desicption"
                      />
                    </div>

                    <div className="modal-buttons">
                      <button
                        onClick={handle_send_notification}
                        className="cusbtn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* pop up for send notification*/}

            
            <form onSubmit={handleSubmit}>
              <div className="Detils_section">
                <div className="card details_img_box">
                  <div className="details_img">
                    <div className="detailsprofilephoto">
                      <img src={name.upload_agenda} alt="" />
                    </div>
                    {/* <input type="file"> Uploade Agenda </input> */}
                  </div>
                </div>

                <div className="card details_box">
                  <div className="details_box_sec">
                    <h2>Event Details</h2>

                    <div className="input-block" style={{ padding: "10px" }}>
                      <label htmlFor="ename" className="input-label">
                        Event Name<span>*</span>
                      </label>
                      <input
                        type="name"
                        className={
                          editInput
                            ? "nocustominputprofile"
                            : "custominputprofile"
                        }
                        autoComplete="off"
                        name="ename"
                        id="ename"
                        readOnly={editInput}
                        placeholder="Event Name"
                        value={values.ename}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.ename && touched.ename ? (
                        <p className="form-error">{errors.ename}</p>
                      ) : null}
                    </div>

                    <div className="details_inputbox">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="startdate" className="input-label">
                          Start Date<span>*</span>
                        </label>
                        <input
                          type="date"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="startdate"
                          id="startdate"
                          readOnly={editInput}
                          placeholder="Start Date"
                          value={values.startdate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.startdate && touched.startdate ? (
                          <p className="form-error">{errors.startdate}</p>
                        ) : null}
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="enddate" className="input-label">
                          End Date<span>*</span>
                        </label>
                        <input
                          type="date"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="enddate"
                          id="enddate"
                          readOnly={editInput}
                          placeholder="End Date"
                          value={values.enddate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.enddate && touched.enddate ? (
                          <p className="form-error">{errors.enddate}</p>
                        ) : null}
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="starttime" className="input-label">
                          Start Time<span>*</span>
                        </label>
                        <input
                          type="time"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="starttime"
                          id="starttime"
                          readOnly={editInput}
                          placeholder="Start Time"
                          value={values.starttime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.starttime && touched.starttime ? (
                          <p className="form-error">{errors.starttime}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="endtime" className="input-label">
                          End Time<span>*</span>
                        </label>
                        <input
                          type="time"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="endtime"
                          id="endtime"
                          readOnly={editInput}
                          placeholder="End Time"
                          value={values.endtime}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.endtime && touched.endtime ? (
                          <p className="form-error">{errors.endtime}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-block" style={{ padding: "10px" }}>
                      <label htmlFor="edesicption" className="input-label">
                        Event Desicption<span>*</span>
                      </label>
                      <textarea
                        className={
                          editInput
                            ? "nocustominputprofile"
                            : "custominputprofile"
                        }
                        name="edesicption"
                        value={values.edesicption}
                        readOnly={editInput}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Event Desicption"
                      />
                    </div>
                    <h2>Venue Details</h2>
                    <div className="details_inputbox">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="venuename" className="input-label">
                          Venue Name<span>*</span>
                        </label>
                        <input
                          type="name"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="venuename"
                          id="venuename"
                          readOnly={editInput}
                          placeholder="Venue Name"
                          value={values.venuename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.venuename && touched.venuename ? (
                          <p className="form-error">{errors.venuename}</p>
                        ) : null}
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="venuelink" className="input-label">
                          Venue Link<span>*</span>
                        </label>
                        <input
                          type="name"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="venuelink"
                          id="venuelink"
                          readOnly={editInput}
                          placeholder="Venue Link"
                          value={values.venuelink}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.venuelink && touched.venuelink ? (
                          <p className="form-error">{errors.venuelink}</p>
                        ) : null}
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="latitude" className="input-label">
                          Latitude<span>*</span>
                        </label>
                        <input
                          type="name"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="latitude"
                          id="latitude"
                          readOnly={editInput}
                          placeholder="Latitude"
                          value={values.latitude}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.latitude && touched.latitude ? (
                          <p className="form-error">{errors.latitude}</p>
                        ) : null}
                      </div>

                      <div className="input-block">
                        <label htmlFor="longitude" className="input-label">
                          Longitude<span>*</span>
                        </label>
                        <input
                          type="number"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="longitude"
                          id="longitude"
                          readOnly={editInput}
                          placeholder="longitude"
                          value={values.longitude}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.longitude && touched.longitude ? (
                          <p className="form-error">{errors.longitude}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-block" style={{ padding: "10px" }}>
                      <label htmlFor="address" className="input-label">
                        Address<span>*</span>
                      </label>
                      <input
                        type="name"
                        className={
                          editInput
                            ? "nocustominputprofile"
                            : "custominputprofile"
                        }
                        autoComplete="off"
                        name="vaddress"
                        id="vaddress"
                        readOnly={editInput}
                        placeholder="vaddress"
                        value={values.vaddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.vaddress && touched.vaddress ? (
                        <p className="form-error">{errors.vaddress}</p>
                      ) : null}
                    </div>

                    {/* <div className="input-block">
                      <label htmlFor="agenda" className="input-label">
                        Upload Agenda <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        disabled={editInput}
                        name="agenda"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => {
                          setFieldValue("agenda", e.currentTarget.files[0]);
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.agenda && errors.agenda ? (
                        <p className="form-error">{errors.agenda}</p>
                      ) : null}
                    </div> */}

                    <div className="modal-buttons">
                      {show_submit_btn && (
                        <button className="cusbtn" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div className="event_notify mt-2">
              <h1>To Event Journey</h1>
              <div className="to_journey">
                <div className="event_notification card">
                  <div className="cardheading">
                    <MdOutlineFlight />
                    <h1>Flight</h1>
                  </div>
                  <div className="deventdatabox">
                    {incoming_Flight ? (
                      incoming_Flight.map((ele, index) => {
                        return (
                          <>
                            <div className="datails_totalbox" key={index}>
                              <div className="flight_icon">
                                <MdFlight />
                              </div>
                              <div className="up_event_content">
                                <h1>{ele.flight_name} <span style={{ fontSize:"12px" ,fontWeight:"normal"}}>({ele.flight_no})</span></h1>
                                {/* <p>{ele.flight_no}</p> */}
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>
                                    <MdOutlineFlightTakeoff />{" "}
                                    {ele.from_location}
                                  </p>
                                  <BsArrowLeftRight />
                                  <p>
                                    {ele.to_location} <MdOutlineFlightLand />
                                  </p>
                                </div>
                                <p
                                  style={{
                                    textAlign: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                  }}
                                >
                                  <BsCalendar2Date />
                                  {ele.checkin_date} <BiTimeFive />{" "}
                                  {ele.checkin_time}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p>No Flights</p>
                    )}
                  </div>
                </div>

                <div className="event_notification card">
                  <div className="cardheading">
                    <AiFillCar />
                    <h1>Cab</h1>
                  </div>
                  <div className="deventdatabox">
                    {incoming_cab ? (
                      incoming_cab.map((ele, index) => {
                        return (
                          <>
                            <div className="datails_totalbox" key={index}>
                              <div className="flight_icon">
                                <AiFillCar />
                              </div>
                              <div className="up_event_content">
                                <h1>{ele.driver_name}</h1>
                                <p>
                                  <MdOutlineCall
                                    size="1em"
                                    style={{ verticalAlign: "middle" }}
                                  />{" "}
                                  {ele.driver_contact_no}
                                </p>
                                <p
                                  style={{
                                    fontSize: "15px",
                                    fontWeight:"bold",
                                    textAlign: "right",
                                  }}
                                >
                                  {ele.cab_no}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p>No Cabs</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="event_notify mt-2">
              <h1>Return Journey</h1>
              <div className="to_journey">
                <div className="event_notification card">
                  <div className="cardheading">
                    <MdOutlineFlight />
                    <h1>Flight</h1>
                  </div>
                  <div className="deventdatabox">
                    {outgoing_Flight ? (
                      outgoing_Flight.map((ele, index) => {
                        return (
                          <>
                            <div className="datails_totalbox" key={index}>
                              <div className="flight_icon">
                                <MdFlight />
                              </div>
                              <div className="up_event_content">
                                <h1>{ele.flight_name} <span style={{ fontSize:"12px" ,fontWeight:"normal"}}>({ele.flight_no})</span></h1>
                                <div
                                  style={{
                                    display: "flex",
                                    marginBottom: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <p>
                                    <MdOutlineFlightTakeoff />{" "}
                                    {ele.from_location}
                                  </p>
                                  <BsArrowLeftRight />
                                  <p>
                                    {ele.to_location} <MdOutlineFlightLand />
                                  </p>
                                </div>
                                <p
                                  style={{
                                    textAlign: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "end",
                                  }}
                                >
                                  <BsCalendar2Date />
                                  {ele.checkin_date} <BiTimeFive />{" "}
                                  {ele.checkin_time}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p>No Flights</p>
                    )}
                  </div>
                </div>

                <div className="event_notification card">
                  <div className="cardheading">
                    <AiFillCar />
                    <h1>Cab</h1>
                  </div>
                  <div className="deventdatabox">
                    {outgoing_cab ? (
                      outgoing_cab.map((ele, index) => {
                        return (
                          <>
                            <div className="datails_totalbox" key={index}>
                              <div className="flight_icon">
                                <AiFillCar />
                              </div>
                              <div className="up_event_content">
                                <h1>{ele.driver_name}</h1>
                                <p>
                                  <MdOutlineCall
                                    size="1em"
                                    style={{ verticalAlign: "middle" }}
                                  />{" "}
                                  {ele.driver_contact_no}
                                </p>
                                <p
                                   style={{
                                    fontSize: "15px",
                                    fontWeight:"bold",
                                    textAlign: "right",
                                  }}
                                >
                                  {ele.cab_no}
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <p>No Cabs</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="event_notify mt-2">
              <div className="event_notification_assistance card">
                <div className="cardheading">
                  <AiOutlineUser />
                  <h1>Assistance Team</h1>
                </div>
                <div className="deventdatabox_assistance">
                  {assistance
                    ? assistance.map((ele, index) => {
                        return (
                          <>
                            <div className="card guestassi_details" key={index}>
                              <div className="datails_totalbox_assistance">
                                <div className="up_event_img">
                                  {ele.profile_image ? (
                                    <img src={ele.profile_image} alt="" />
                                  ) : (
                                    <div className="flight_icon">
                                      <FaUser />
                                    </div>
                                  )}
                                </div>
                                <div className="up_event_content">
                                  <h1>{ele.name}</h1>

                                  <div className="up_event_meta">
                                    <MdOutlineCall />
                                    <p>{ele.contact_no}</p>
                                  </div>
                                  <div className="up_event_meta">
                                    <FaUserTie Size="1em" />
                                    <p>{ele.support_type}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Guest_event_detail;
