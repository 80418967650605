import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { AiOutlineClose } from "react-icons/ai";
import { BiLeftArrow, BiUpload } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch } from "react-redux";
import uploadImg from "../../components/photos/uploadimg.png";
import csv from "../../components/photos/csv.png";
import { useSelector } from "react-redux";
import { SETASSISTANCEAPI } from "../../redux/bulkbtns/Actions";
import { CgCloseR } from "react-icons/cg";
import assistance_details from "../../assistance_details.csv";
import dp from "../../components/photos/dp.jpg";
import MOCK_DATA from "./Customers.json";
import Assign from "./Assign";
import { MultiSelect } from "react-multi-select-component";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import Footer from "../Footer";
import { FaUser } from "react-icons/fa";

const Assistance = () => {
  const navigate = useNavigate();
  const [deisgnation, setDeisgnation] = useState(false);

  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ",flight_data.ASSISTANCE_TABLE_DATA)

  const [upcoming, setUpcoming] = useState(false);
  useEffect(() => {
    assistance_table_detail();

    if (flight_data.ASSISTANCE_TABLE_DATA) {
      setUpcoming(true);
    }
  }, []);

  // assistance table api
  async function assistance_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_assistance.php")
        .then((response) => {
          if (response.data.status == true) {
            dispatch(SETASSISTANCEAPI(response.data.data));
          } else {
            dispatch(SETASSISTANCEAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETASSISTANCEAPI([]));
      console.log(e);
    }
  }

  const data = useMemo(() => {
    if (upcoming) {
      return flight_data.ASSISTANCE_TABLE_DATA;
    } else {
      return [];
    }
  }, [upcoming, flight_data.ASSISTANCE_TABLE_DATA]);

  // assistance table api
  // useEffect

  // Create Event Popup submit Start
  const signUpSchema = Yup.object({
    ateamprofile: Yup.mixed()
      .required("Upload Assistance Profile")
      .test("fileSize", "Max Size 2MB", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
    assistance_name: Yup.string()
      .required("Enter Assistance Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets and spaces"),
    // select_event: Yup.string().required("Select Event"),
    assistance_designation: Yup.string().required(
      "Enter Assistance Designation"
    ),
    contactnumber: Yup.string()
      .required("Enter Contact Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    whatsapp_number: Yup.string()
      .required("Enter Whatsapp Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    assistance_designation: Yup.string().required(
      "Select Assistance Designation"
    ),
    support_type: Yup.string().required("Select Assistance Support Type"),
  });

  const [initialValues, setinitialValues] = useState({
    // select_event: "",
    ateamprofile: "",
    assistance_name: "",
    assistance_designation: "",
    contactnumber: "",
    whatsapp_number: "",
    support_type: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      assistance_form(values);
      action.resetForm();
    },
  });
  // event api
  async function assistance_form(e) {
    // dataa.append("agenda", e.agenda.fileInput.files[0]);
    var dataa = new FormData();
    // dataa.append("select_event", e.select_event);
    dataa.append("profile", e.ateamprofile);
    dataa.append("name", e.assistance_name);
    dataa.append("designation", e.assistance_designation);
    dataa.append("contact_no", e.contactnumber);
    dataa.append("whatsapp_no", e.whatsapp_number);
    dataa.append("support_type", e.support_type);
    // console.log(dataa);
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/assistance.php", dataa)
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            alert("Data uploaded successfully");
            setAssistance_Team_Create(!Assistance_Team_Create);
            window.location.reload();
          } else {
            alert("File not upload");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // event api

  // upload file
  const [uploadfile, setUploadfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [select_event_upload, setSelect_event_upload] = useState();
  const fileInputRef = useRef(null);

  const submitfile = (e) => {
    e.preventDefault();
    const files = fileInputRef.current.files;
    console.log(files);

    if (files.length === 0) {
      alert("Select File");
      return;
    }
    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("file", files[0]);

      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/uploadassistance.php", dataa)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == true) {
              alert("Successfully Uploaded");
              setUploadfile(!uploadfile);
              window.location.reload();
            } else {
              alert(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // upload file

  // Create Event
  const [Assistance_Team_Create, setAssistance_Team_Create] = useState(false);

  // Create Event

  const COLUMNS = [
    {
      Header: "Assistance Image",
      accessor: "profile_image",
      Cell: ({ row }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginLeft: "33px",
            }}
          >
            {row.original.profile_image ? (
              <img
                src={row.original.profile_image}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
                alt=""
              />
            ) : (
              <div className="flight_icon">
                <FaUser />
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      Header: "Assistance Name",
      accessor: "name",
      Cell: ({ row }) => (
        <>
          <p>{row.original.name}</p>
        </>
      ),
    },
    {
      Header: "Designation",
      accessor: "designation",
      Cell: ({ row }) => (
        <>
          <p>{row.original.designation}</p>
        </>
      ),
    },
    {
      Header: "Contact Number",
      accessor: "contact_no",
      Cell: ({ row }) => (
        <>
          <p>{row.original.contact_no}</p>
        </>
      ),
    },
    {
      Header: "WhatsApp Number",
      accessor: "whatsapp_no",
      Cell: ({ row }) => (
        <>
          <p>{row.original.whatsapp_no}</p>
        </>
      ),
    },
    {
      Header: "Support Type",
      accessor: "support_type",
      Cell: ({ row }) => (
        <>
          <p>{row.original.support_type}</p>
        </>
      ),
    },
    // {
    //   Header: "Action",
    //   accessor: "modifiedtime",
    //   Cell: ({ row }) => (
    //     <>
    //       <Assign />
    //     </>
    //   ),
    // },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="main-container">
      <MainSidebar />
      <div className="allcontent">
        <Navbar />
        {/* <Breadcrumbs/> */}
        <div className="mainbox">
          {/* pop up for Create Event event*/}
          {Assistance_Team_Create && (
            <div className="modal">
              <div
                onClick={() => {
                  setAssistance_Team_Create(!Assistance_Team_Create);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create Assistance Team</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setAssistance_Team_Create(!Assistance_Team_Create);
                    }}
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                <div style={{ minHeight: "380px" }} className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="input-block">
                      <label htmlFor="ateamprofile" className="input-label">
                        Upload Assistance Profile Pic{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="ateamprofile"
                        accept=".png,.jpg,.jpeg"
                        onChange={(e) => {
                          setFieldValue(
                            "ateamprofile",
                            e.currentTarget.files[0]
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.ateamprofile && errors.ateamprofile ? (
                        <p className="form-error">{errors.ateamprofile}</p>
                      ) : null}
                    </div>
                    <div className="input-block">
                      <label htmlFor="assistance_name" className="input-label">
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="name"
                        className="custominput"
                        autoComplete="off"
                        name="assistance_name"
                        id="assistance_name"
                        placeholder="Name"
                        value={values.assistance_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.assistance_name && errors.assistance_name ? (
                        <p className="form-error">{errors.assistance_name}</p>
                      ) : null}
                    </div>
                    <div className="input-block">
                      <label
                        htmlFor="assistance_designation"
                        className="input-label"
                      >
                        Designation <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="assistance_designation"
                        id="assistance_designation"
                        placeholder="assistance_designation"
                        value={values.assistance_designation}
                        onClick={() => {
                          setDeisgnation(!deisgnation);
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Designation</option>
                        <option value="Customer Support">
                          Customer Support
                        </option>
                        <option value="Client Executive">
                          Client Executive
                        </option>
                        <option value="Finance Executive">
                          Finance Executive
                        </option>
                        <option value="Marketing Executive">
                          Marketing Executive
                        </option>
                        <option value="Technical Executive">
                          Technical Executive
                        </option>
                        <option value="Tour Manager">
                          Tour Manager
                        </option>
                        <option value="Tour Guide">
                          Tour Guide
                        </option>
                        <option value="Human Executive">Human Executive</option>
                      </select>
                      {errors.assistance_designation &&
                      touched.assistance_designation ? (
                        <p className="form-error">
                          {errors.assistance_designation}
                        </p>
                      ) : null}
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="contactnumber" className="input-label">
                          Contact Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          maxLength={10}
                          className="custominput"
                          autoComplete="off"
                          name="contactnumber"
                          id="contactnumber"
                          placeholder="xxxxxxxxxx"
                          value={values.contactnumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.contactnumber && touched.contactnumber ? (
                          <p className="form-error">{errors.contactnumber}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label
                          htmlFor="whatsapp_number"
                          className="input-label"
                        >
                          WhatsApp Number{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          maxLength={10}
                          className="custominput"
                          autoComplete="off"
                          name="whatsapp_number"
                          id="whatsapp_number"
                          placeholder="xxxxxxxxxx"
                          value={values.whatsapp_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.whatsapp_number && touched.whatsapp_number ? (
                          <p className="form-error">{errors.whatsapp_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-block">
                      <label htmlFor="support_type" className="input-label">
                        Support Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="support_type"
                        id="support_type"
                        placeholder="Support Type"
                        value={values.support_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option>Select Support</option>
                        <option value="SPOC">SPOC</option>
                        <option value="Travel Assistance">
                          Travel Assistance
                        </option>
                        <option value="Event Assistance">
                          Event Assistance
                        </option>
                        <option value="Hotel Assistance">
                          Hotel Assistance
                        </option>
                      </select>
                      {errors.support_type && touched.support_type ? (
                        <p className="form-error">{errors.support_type}</p>
                      ) : null}
                    </div>
                    <div className="modal-buttons">
                      <button className="cusbtn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* pop up for Create Event event*/}

          {/* pop up for upload file*/}
          {uploadfile && (
            <div className="modal">
              <div
                onClick={() => {
                  setUploadfile(!uploadfile);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2> Assistance Upload (Bulk)</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  {/* <form  > */}

                  <div className="download mt-2">
                    <a
                      href={assistance_details}
                      download="Assistance_sample.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="cusbtn">
                        Download sample.csv file
                      </button>
                    </a>
                  </div>

                  <div className="input-block">
                    <label htmlFor="uploademailfile" className="input-label">
                      Upload Assistance Details{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="setuploadfile">
                      <div className="app">
                        <div className="parent">
                          <div className="file-upload">
                          {selectedFile ? (
                              <img style={{ height:"45px" }}  src={csv} alt="upload" />
                            ) : (
                              <img className="after_file_upload" src={uploadImg} alt="upload" />
                            )}
                            {
                              selectedFile?
                              <h3>{selectedFile.name}</h3>:
                              <h3>Click box to upload</h3>

                            }
                            <input
                              type="file"
                              style={{ color: "var(--Whiteblack-color)" }}
                              name="file"
                              accept=".csv"
                              onChange={(e) => {setSelectedFile(e.target.files[0])}}
                              ref={fileInputRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-buttons">
                    <button onClick={submitfile} className="cusbtn">
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          )}
          {/* pop up for upload file*/}

          <div className="card">
            <div
              className="department tablebox"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                    className="cusbtn"
                  >
                    <BiUpload
                      size="1.5em"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    Bulk Assistance Upload
                  </button>

                  <button
                    onClick={() => {
                      setAssistance_Team_Create(!Assistance_Team_Create);
                    }}
                    className="cusbtn"
                  >
                    <BiPlusMedical size="1.2rem" />
                  </button>
                </div>

              </div>
              {/* pagination */}

              <table className="customertable" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {/* {
                    flight_data.ASSISTANCE_TABLE_DATA ?
                    <> */}
                       {page.length === 0 ? (
                    <tr>
                      <td>No data</td>
                    </tr>
                  ) : (
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td
                                style={{
                                  textalign: "center",
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                    {/* </> :""
                  } */}
                  
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Assistance;
