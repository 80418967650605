import React from 'react'
import { useNavigate } from "react-router-dom";
import logo from "../../components/photos/wizsuite.png";
import dot from "../../components/photos/dotimg.png";
import { useFormik } from "formik";
import { BiUser } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";
import { AiOutlineProfile } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import * as Yup from "yup";

const Sign_Up = () => {
    const navigate = useNavigate();

    const style = {
        backgroundImage: `url(${dot})`,
        backgroundSize: "276px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right top",
    };

    const initialValues = {
        name: "",
        email: "",
        phone: "",
        designation: "",
        password: "",
    };

    const signUpSchema = Yup.object({
        name: Yup.string().min(2).required("Enter name"),
        email: Yup.string()
            .email("Must be a valid email")
            .required("Enter Email"),
        phone: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, 'Enter your 10 Digit number')
      .max(10, 'Enter your 10 Digit number'),
      designation: Yup.string().required("Select Designation"),
        password: Yup.string()
            .min(2)
            .required("Enter Password"),
    });

    const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
        useFormik({
            initialValues,
            validationSchema: signUpSchema,
            validateOnChange: true,
            validateOnBlur: false,
            //// By disabling validation onChange and onBlur formik will validate on submit.
            onSubmit: (values, action) => {
                console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
                //// to get rid of all the values after submitting the form
                alert("form Submitted");
                action.resetForm();
            },
        });

    return (
        <>
            <div className="loginbg">
                <div className="loginbox">
                    <div className="image">
                        {/* <img src={login} alt="" /> */}
                        <div></div>
                    </div>
                    <div style={style} className="form">
                        {/* <div className="logindetail"> */}
                        {/* <img className="loginlogo" src={logo} alt="" /> */}
                        <div className="formbox" style={{ marginTop: "5px" }}>
                            <h1 className="loginhead">Create An Account</h1>
                            <p>Please signup to continue</p>

                            <div style={{ marginTop: "20px" }}>
                                <form onSubmit={handleSubmit}>
                                    <div className="input-block">
                                        <label htmlFor="name" className="loginlabel">Name</label>
                                        <div className="custominputlogo">
                                            <BiUser />
                                            <input
                                                type="text"
                                                // autoComplete='off'
                                                name="name"
                                                id="name"
                                                placeholder="Name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div style={{ height: "20px", textAlign: "left" }}>
                                            {errors.name && touched.name ? (
                                                <p className="form-error">{errors.name}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="email" className="loginlabel">Email</label>
                                        <div className="custominputlogo">
                                            <AiOutlineMail />
                                            <input
                                                type="text"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div style={{ height: "20px", textAlign: "left" }}>
                                            {errors.email && touched.email ? (
                                                <p className="form-error">{errors.email}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="phone" className="loginlabel">Phone</label>
                                        <div className="custominputlogo">
                                            <BsPhone />
                                            <input
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                maxLength={10}
                                                placeholder="Phone"
                                                value={values.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div style={{ height: "20px", textAlign: "left" }}>
                                            {errors.phone && touched.phone ? (
                                                <p className="form-error">{errors.phone}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="designation" className="loginlabel">Designation</label>
                                        <div className="custominputlogo">
                                            <AiOutlineProfile />
                                            <select
                                                name="designation"
                                                id="designation"
                                                placeholder="designation"
                                                value={values.designation}
                                                // onClick={ ()=> {setDeisgnation(!deisgnation)}}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option>Select Designation</option>
                                                <option value="customer_support">Customer Support</option>
                                                <option value="customer_support">Customer Support</option>
                                            </select>
                                        </div>
                                        <div style={{ height: "20px", textAlign: "left" }}>
                                            {errors.designation && touched.designation ? (
                                                <p className="form-error">{errors.designation}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="input-block">
                                        <label className="loginlabel">Password</label>
                                        <div className="custominputlogo">
                                            <RiLockPasswordLine />
                                            <input
                                                type="text"
                                                name="password"
                                                id="password"
                                                placeholder="Password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div style={{ height: "20px", textAlign: "left" }}>
                                            {errors.password && touched.password ? (
                                                <p className="form-error">{errors.password}</p>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div >
                                        <button type="submit" className="cusbtnlogo">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* </div> */}
                        </div>

                        <p className="bottomlinetext">Already have an account?
                            <span
                                onClick={() => {
                                    navigate("/");
                                }}
                                style={{ color: "var(--primary-color)", fontWeight: "bold", cursor: "pointer" }}> Sign in</span>
                        </p>

                        {/* <button
              onClick={() => {
                navigate("/Dashboard/Dashboard.jsx");
              }}
            >
              Go to
            </button> */}
                    </div>
                </div>
            </div>


            <div className="login">
            </div>
        </>
    );
};

export default Sign_Up
