import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../components/photos/wizsuite.png";
import axios from "axios";
import dot from "../../components/photos/dotimg.png";
import { useFormik } from "formik";
import { BiUser } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SETGUESTAPI } from "../../redux/bulkbtns/Actions";
import { useEffect } from "react";
import login from "../../components/photos/login.jpg";

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    guest_table_detail();
  }, []);
  // guest table api
  async function guest_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_guest.php")
        .then((response) => {
          if (response.data.status == true) {
            dispatch(SETGUESTAPI(response.data.data));
          } else {
            dispatch(SETGUESTAPI([]));
          }
          // setFlight_table(response.data.data);
        });
    } catch (e) {
      dispatch(SETGUESTAPI([]));
      console.log(e);
    }
  }
  // guest table api

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "276px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const signUpSchema = Yup.object({
    password: Yup.string()
      .min(2, "This is a required field")
      .required("This is a required field"),

    email: Yup.string()
      .email("Must be a valid email")
      .required("This is a required field"),
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);

        login(values);
        action.resetForm();
      },
    });

  // login api
  async function login(e) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/adminlogin.php", {
          email: e.email,
          password: e.password,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            navigate("/Dashboard/Dashboard.jsx");
          } else {
            alert(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
      alert("Log in Failed");
    }
  }
  // login api

  return (
    <>
      <div className="loginbg">
        <div className="loginbox">
          <div className="image">
            {/* <img src={login} alt="" /> */}
            <div></div>
          </div>
          <div style={style} className="form">
            {/* <div className="logindetail"> */}
            <img className="loginlogo" src={logo} alt="" />
            <div className="formbox">
              <h1 className="loginhead">Welcome To WizSuite</h1>
              <p>Please Sign in to continue.</p>

              <div style={{ marginTop: "20px" }}>
                <form onSubmit={handleSubmit}>
                  <div className="input-block">
                    <label className="loginlabel">Email</label>
                    <div className="custominputlogo">
                      <BiUser />
                      <input
                        type="text"
                        // autoComplete='off'
                        name="email"
                        id="name"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.email && touched.email ? (
                        <p className="form-error">{errors.email}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="input-block">
                    <label className="loginlabel">Password</label>
                    <div className="custominputlogo">
                      <RiLockPasswordLine />
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.password && touched.password ? (
                        <p className="form-error">{errors.password}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="forgetpass">
                    <div className="rememberme">
                      <input type="checkbox" />
                      <p>Remember me</p>
                    </div>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("LoginScreens/Forgetpass");
                      }}
                    >
                      Forgot Password?
                    </p>
                  </div>
                  <div>
                    <button type="submit" className="cusbtnlogo">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              {/* </div> */}
            </div>

            <p className="bottomlinetext">
              Don’t Have An Account?
              <span
                onClick={() => {
                  navigate("/LoginScreens/Sign_Up");
                }}
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {" "}
                Sign up
              </span>{" "}
            </p>

            {/* <button
              onClick={() => {
                navigate("/Dashboard/Dashboard.jsx");
              }}
            >
              Go to
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
