import * as openclose from "./Constants.jsx";

export const SetSideMenuOpenClose = (payload)  => ({
    type: openclose.SET_SIDE_MENU_OPEN_CLOSE,
    payload,
});

export const SETLIGHTDARKMODE = (payload) => ({
  type: openclose.SET_LIGHT_DARK_MODE,
  payload,
});

export const SETCHECKEDMODE = (payload) => ({
    type: openclose.SET_CHECKED_MODE,
    payload,
  });

  export const SETFLIGHTAPI = (payload) => ({
    type: openclose.SET_FLIGHT_TABLE,
    payload,
  });
  
  export const SETEVENTAPI = (payload) => ({
    type: openclose.SET_EVENT_TABLE,
    payload,
  });
  
  export const SETGUESTAPI = (payload) => ({
    type: openclose.SET_GUEST_TABLE,
    payload,
  });

  
  export const SETASSISTANCEAPI = (payload) => ({
    type: openclose.SET_ASSISTANCE_TABLE,
    payload,
  });

  export const SETCABAPI = (payload) => ({
    type: openclose.SET_CAB_TABLE,
    payload,
  });

  export const SETUPCOMINGGUEST = (payload) => ({
    type: openclose.SET_UPCOMING_GUEST_TABLE,
    payload,
  });

  export const SETOLDGUEST = (payload) => ({
    type: openclose.SET_OLD_GUEST_TABLE,
    payload,
  });

  export const SETACTiVELINK = (payload) => ({
    type: openclose.SET_ACTIVE_LINK,
    payload,
  });


