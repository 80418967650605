import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { CgCloseR } from "react-icons/cg";
import axios from "axios";

const Assign = (props) => {
  // console.log("id value= ",props.id);
  const [modal, setModal] = useState(false);
  // const toggleModal = () => {
  //   setModal(!modal);
  // };

  const [options, setOptions] = useState();

  useEffect(() => {
    flightassign();
  }, []);

  // flight api

  const customOptionRenderer = (option) => {
    return (
      <>
        {/* <span>{option.label}</span> */}
        <span style={{ marginLeft: '8px' }}>{option.city}</span>
      </>
    );
  };

  async function flightassign() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_guest.php")
        .then((response) => {
          // console.log("event assign", response.data.data);

          // chnage key name here
          function selectKeyValuesFromArray(array, keyMapping) {
            const selectedArray = [];

            // Iterate over the array of objects
            for (let i = 0; i < array.length; i++) {
              const originalObject = array[i];
              const selectedObject = {};

              // Create a new object with the desired key-value pairs
              for (const originalKey in keyMapping) {
                if (originalObject.hasOwnProperty(originalKey)) {
                  const selectedKey = keyMapping[originalKey];
                  selectedObject[selectedKey] = originalObject[originalKey];
                }
              }

              // Push the selected object into the new array
              selectedArray.push(selectedObject);
            }
            // setOptions(selectedArray)
            return selectedArray;
          }

          const a = selectKeyValuesFromArray(response.data.data, {
             name: "label",
            id: "value",
            // city: "city"
          });
          setOptions(a);
          console.log("a= ", a);
          // console.log("options= ", options);
          // chnage key name here
        });
    } catch (e) {
      console.log(e);
    }
  }
  // flight api

  // assign multiple submit

  function submit_assign(array, keyMapping) {
    if(array.length === 0){
      alert("Please Select At least 1 guest");
      return;
    }
    const selectedArray = [];

    // Iterate over the array of objects
    for (let i = 0; i < array.length; i++) {
      const originalObject = array[i];
      const selectedObject = {};

      // Create a new object with the desired key-value pairs
      for (const originalKey in keyMapping) {
        if (originalObject.hasOwnProperty(originalKey)) {
          const selectedKey = keyMapping[originalKey];
          selectedObject[selectedKey] = originalObject[originalKey];
        }
      }

      // Push the selected object into the new array
      selectedArray.push(selectedObject);
    }
    // flight api
    flightassign();
    async function flightassign() {
      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/assign_user.php", {
            user_id: selectedArray,
            events_id: props.id
          })
          .then((response) => {
            console.log("ASSIGN event to guest =", response.data);
            if(response.data.status == true){
              alert(response.data.message);
              setModal(!modal);
            }
            else{
              response.data.map((ele,index) => {
                alert(ele.message);
              })
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
    // flight api
    console.log("on submit =", selectedArray);
    return selectedArray;
  }
  // assign multiple submit

  // select multiple
  const [selected, setSelected] = useState([]);
  // console.log(selected)
  // select multiple

  return (
    <>
      {/* pop up for assign event*/}
      {modal && (
        <div className="modal">
          <div onClick={() => { setModal(!modal) }} className="overlay"></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Assign Event to Guest</h2>
              <button className="close-modal" onClick={() => { setModal(!modal) }}>
                <CgCloseR />
              </button>
            </div>

            {options ? (
              <div style={{ minHeight: "380px" }} className="modal-body">
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="Select"
                  getOptionLabel={customOptionRenderer}
                />
              </div>
            ) : (
              ""
            )}
            <div className="assign_submit_btn">
              <button
                className="cusbtn"
                onClick={() => {
                  submit_assign(selected, { value: "id" });
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      {/* pop up for assign event*/}
      <div
        onClick={() => { setModal(!modal) }}
        style={{
          display: "inline-flex",
          marginRight: "4px",
          justifyContent: "center",
        }}
      >
        <button className="cusbtn">Assign</button>
      </div>
    </>
  );
};

export default Assign;
