import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/LoginScreens/Login";
import Forgetpass from "./pages/LoginScreens/Forgetpass";
import Dashboard from "./pages/Dashboard/Dashboard.jsx";
import Event from "./pages/Event/Event.jsx";
import Breadcrumbs from "./breadcrumbs/Breadcrumbs.jsx";
import Guest from "./pages/Guest/Guest.jsx";
import Assistance from "./pages/Assistance/Assistance.jsx";

import Flight from "./pages/Flight/Flight.jsx";
import Flight_Details from "./pages/Flight/Flight_Details.jsx";

import Cab from "./pages/Cab/Cab.jsx";
import Profile from "./pages/AdminProfile/Profile";
import Sign_Up from "./pages/LoginScreens/Sign_Up";
import Otp from "./pages/LoginScreens/Otp";
import Newpassword from "./pages/LoginScreens/Newpassword";
import Event_Details from "./pages/Event/Event_Details.jsx";
import Guest_Details from "./pages/Guest/Guest_Details.jsx";
import MainSidebar from "./components/Sidebar/MainSidebar";
import Guest_event_detail from "./pages/Guest/Guest_event_detail";

function App() {
  return (
    <Router>
      <SideBar>
          {/* <MainSidebar/> */}
          
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard/Dashboard.jsx" element={<Dashboard />} />
          <Route path="/Event/Event.jsx" element={<Event />} />
          <Route path="/Event/Event_Details" element={<Event_Details />} />

          <Route path="/Guest/Guest.jsx" element={<Guest />} />
          <Route path="/Guest/Guest_Details" element={<Guest_Details />} />
          <Route path="/Guest/Guest_event_detail" element={<Guest_event_detail />} />

          <Route path="/Assistance/Assistance.jsx" element={<Assistance />} />
          
          <Route path="/Flight/Flight.jsx" element={<Flight />} />
          <Route path="/Flight/Flight_Details" element={<Flight_Details/>} />
          <Route path="/Cab/Cab.jsx" element={<Cab />} />
         
          <Route path="/AdminProfile/Profile" element={<Profile />} />


          <Route path="/LoginScreens/Forgetpass" element={<Forgetpass />} />
          <Route path="/LoginScreens/Otp" element={<Otp />} />
          <Route path="/LoginScreens/Newpassword" element={<Newpassword />} />
          <Route path="/LoginScreens/Sign_Up" element={<Sign_Up />} />
    

          <Route path="./breadcrumbs/Breadcrumbs.jsx" element={<Breadcrumbs />} />
         
          <Route path="*" element={<> not found</>} />
        </Routes>
        
      </SideBar>
    </Router>
  );
}

export default App;
