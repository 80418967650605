import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { BiLeftArrow, BiUpload } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import * as Yup from "yup";
import Assign from "./Assign";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SETGUESTAPI } from "../../redux/bulkbtns/Actions";
import axios from "axios";
import { CgCloseR } from "react-icons/cg";
import EmailsCsv from "../../Emails.csv";
import uploadImg from "../../components/photos/uploadimg.png";
import csv from "../../components/photos/csv.png";
import GuestCsv from "../../guest_details.csv";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import EmailsExcel from "../../Emailsexcel.xlsx";
import MOCK_DATA from "./Customers.json";
import dp from "../../components/photos/dp.jpg";
import { MultiSelect } from "react-multi-select-component";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import Footer from "../Footer";
import { GlobalFilter } from "./GlobalFilter";

const Guest = () => {
  const navigate = useNavigate();
  const [govtidstate, setgovtidstate] = useState(false);

  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ",flight_data.GUEST_TABLE_DATA)

  const [upcoming, setUpcoming] = useState(false);
  useEffect(() => {
    guest_table_detail();
    if (flight_data.GUEST_TABLE_DATA) {
      setUpcoming(true);
    }
  }, [upcoming]);

  // guest table api
  async function guest_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_guest.php")
        .then((response) => {
          if (response.data.status == true) {
            dispatch(SETGUESTAPI(response.data.data));
          } else {
            dispatch(SETGUESTAPI([]));
          }
          // setFlight_table(response.data.data);
        });
    } catch (e) {
      dispatch(SETGUESTAPI([]));
      console.log(e);
    }
  }

  const data = useMemo(() => {
    if (upcoming) {
      return flight_data.GUEST_TABLE_DATA;
    } else {
      return [];
    }
  }, [upcoming, flight_data.GUEST_TABLE_DATA]);

  // guest table api
  // useEffect

  // Create Event Popup submit Start
  const signUpSchema = Yup.object({
    uploadgovt_id: Yup.mixed()
      .required("upload id")
      .test("fileSize", "Max Size 2MB", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
    name: Yup.string()
      .required("Enter Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets and spaces"),
    phone: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    email: Yup.string().required("Enter Email"),
    city: Yup.string().required("Enter City"),
    t_shirt: Yup.string().required("Enter T-Shirt Size"),
    govt_id: Yup.string().required("Select Govt Id"),
  });

  const [initialValues, setinitialValues] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    t_shirt: "",
    govt_id: "",
    uploadgovt_id: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      guest_form(values);
      action.resetForm();
    },
  });

  // guest api
  async function guest_form(e) {
    // dataa.append("agenda", e.agenda.fileInput.files[0]);
    var dataa = new FormData();
    dataa.append("name", e.name);
    dataa.append("phone", e.phone);
    dataa.append("email", e.email);
    dataa.append("city", e.city);
    dataa.append("t_shirt", e.t_shirt);
    dataa.append("govermt_id_type", e.govt_id);
    dataa.append("govermt", e.uploadgovt_id);
    // console.log(dataa);
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/guest.php", dataa)
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == true) {
            alert("Successfully Registered");
            setCreate_event_modal(!Create_event_modal);
            window.location.reload();
          } else {
            alert("Either Email or Phone Number Already Exist");
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // guest api

  // upload file
  const [uploadfile, setUploadfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [select_event_upload, setSelect_event_upload] = useState();
  // const [csvfile, setCsvfile] = useState();
  const fileInputRef = useRef(null);

  const submitfile = (e) => {
    e.preventDefault();
    const files = fileInputRef.current.files;
    console.log(files);
    if (select_event_upload.trim().length == 0) {
      alert("Select Event");
      return;
    }

    if (files.length === 0) {
      alert("Select File");
      return;
    }
    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("file", files[0]);
      dataa.append("event_id", select_event_upload);

      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/uploadFile.php", dataa)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == true) {
              alert("Successfully Uploaded");
              setUploadfile(!uploadfile);
            } else {
              alert(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // upload file

  // upload bulk guest
  const [bulk_guest, setBulk_guest] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState();
  // const [csvfile, setCsvfile] = useState();
  const bulk_guest_ref = useRef(null);

  const submit_bulk_file = (e) => {
    e.preventDefault();
    const files = bulk_guest_ref.current.files;
    console.log(files);

    if (files.length === 0) {
      alert("Select File");
      return;
    }
    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("file", files[0]);

      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/uploadguest.php", dataa)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == true) {
              alert("Successfully Uploaded");
              setBulk_guest(!bulk_guest);
            } else {
              alert(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // upload bulk guest

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);

  // Create Event

  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/Guest/Guest_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            {row.original.name}
          </p>
        </>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => (
        <>
          <p>{row.original.phone}</p>
        </>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <>
          <p>{row.original.email}</p>
        </>
      ),
    },
    {
      Header: "City",
      accessor: "city",
      Cell: ({ row }) => (
        <>
          <p>{row.original.city}</p>
        </>
      ),
    },
    {
      Header: "T-Shirt",
      accessor: "t_shirt",
      Cell: ({ row }) => (
        <>
          <p>{row.original.t_shirt}</p>
        </>
      ),
    },
    {
      Header: "Govt ID",
      accessor: "govermt_id_type",
      Cell: ({ row }) => (
        <>
          <p>{row.original.govermt_id_type}</p>
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <>
          <Assign userid={row.original.id} />
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="main-container">
      <MainSidebar />
      <div className="allcontent">
        <Navbar />
        {/* <Breadcrumbs/> */}
        <div className="mainbox">
          {/* pop up for Create Event event*/}
          {Create_event_modal && (
            <div className="modal">
              <div
                onClick={() => {
                  setCreate_event_modal(!Create_event_modal);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create Guest</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setCreate_event_modal(!Create_event_modal);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div style={{ minHeight: "380px" }} className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="name" className="input-label">
                          Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.name && errors.name ? (
                          <p className="form-error">{errors.name}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="phone" className="input-label">
                          Phone <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="tel"
                          className="custominput"
                          autoComplete="off"
                          maxLength={10}
                          name="phone"
                          id="phone"
                          placeholder="Phone"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p className="form-error">{errors.phone}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="email" className="input-label">
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          className="custominput"
                          autoComplete="off"
                          name="email"
                          id="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="city" className="input-label">
                          City <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="city"
                          id="city"
                          placeholder="City"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city ? (
                          <p className="form-error">{errors.city}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input-block">
                      <label htmlFor="t_shirt" className="input-label">
                        T-Shirt <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        type="name"
                        className="custominput"
                        autoComplete="off"
                        name="t_shirt"
                        id="t_shirt"
                        placeholder="T-Shirt"
                        value={values.t_shirt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled value="">
                          Select Size
                        </option>
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="XXL">XXL</option>
                        <option value="3XL">3XL</option>
                      </select>
                      {errors.t_shirt && touched.t_shirt ? (
                        <p className="form-error">{errors.t_shirt}</p>
                      ) : null}
                    </div>

                    <div className="input-block">
                      <label htmlFor="govt_id" className="input-label">
                        Govt ID <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="govt_id"
                        id="govt_id"
                        onClick={() => {
                          setgovtidstate(!govtidstate);
                        }}
                        placeholder="Govt Id"
                        value={values.support_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled={govtidstate}>Select Govt Id</option>
                        <option value="Passport">Passport</option>
                        <option value="aadhaar_card">Aadhaar card</option>
                        <option value="pan_card">Pan Card</option>
                      </select>
                      {errors.govt_id && touched.govt_id ? (
                        <p className="form-error">{errors.govt_id}</p>
                      ) : null}
                    </div>

                    <div className="input-block">
                      <label htmlFor="uploadgovt_id" className="input-label">
                        Upload Govt Id "PDF Only"{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        name="uploadgovt_id"
                        accept=".pdf"
                        placeholder="uploadgovt_id"
                        onChange={(e) => {
                          handleChange(e);
                          setFieldValue(
                            "uploadgovt_id",
                            e.currentTarget.files[0]
                          );
                        }}
                        onBlur={handleBlur}
                      />
                      {touched.uploadgovt_id && errors.uploadgovt_id ? (
                        <p className="form-error">{errors.uploadgovt_id}</p>
                      ) : null}
                    </div>
                    <div className="modal-buttons">
                      <button className="cusbtn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* pop up for Create Event event*/}

          {/* pop up for upload file*/}
          {uploadfile && (
            <div className="modal">
              <div
                onClick={() => {
                  setUploadfile(!uploadfile);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create Guest</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  {/* <form  > */}

                  <div className="input-block">
                    <label htmlFor="select_event" className="input-label">
                      Select Event <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="custominput selectoption"
                      name="select_event"
                      id="select_event"
                      onChange={(e) => {
                        setSelect_event_upload(e.target.value);
                      }}
                    >
                      <option value="">Select Event</option>
                      {flight_data.EVENT_TABLE_DATA
                        ? flight_data.EVENT_TABLE_DATA.map((ele, index) => {
                            return (
                              <>
                                <option index={index} value={ele.id}>
                                  {ele.events_name}
                                </option>
                              </>
                            );
                          })
                        : ""}
                    </select>
                  </div>

                  <div className="download mt-2">
                    <a
                      href={EmailsCsv}
                      download="sample.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="cusbtn">
                        Download sample.csv file
                      </button>
                    </a>
                  </div>

                  <div className="input-block">
                    <label htmlFor="uploademailfile" className="input-label">
                      Upload Email List <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="setuploadfile">
                      <div className="app">
                        <div className="parent">
                          <div className="file-upload">
                            {selectedFile ? (
                              <img
                                style={{ height: "45px" }}
                                src={csv}
                                alt="upload"
                              />
                            ) : (
                              <img
                                className="after_file_upload"
                                src={uploadImg}
                                alt="upload"
                              />
                            )}
                            {selectedFile ? (
                              <h3>{selectedFile.name}</h3>
                            ) : (
                              <h3>Click box to upload</h3>
                            )}
                            <input
                              type="file"
                              style={{ color: "var(--Whiteblack-color)" }}
                              name="file"
                              onChange={(e) => {
                                setSelectedFile(e.target.files[0]);
                              }}
                              accept=".csv"
                              ref={fileInputRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-buttons">
                    <button onClick={submitfile} className="cusbtn">
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          )}
          {/* pop up for upload file*/}

          {/* pop up for bulk upload guest*/}
          {bulk_guest && (
            <div className="modal">
              <div
                onClick={() => {
                  setBulk_guest(!bulk_guest);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Guest Upload (Bulk)</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setBulk_guest(!bulk_guest);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  {/* <form  > */}

                  <div className="download mt-2">
                    <a
                      href={GuestCsv}
                      download="Guest_sample.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="cusbtn">
                        Download sample.csv file
                      </button>
                    </a>
                  </div>

                  <div className="input-block">
                    <label htmlFor="uploademailfile" className="input-label">
                      Upload Guest Details
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="setuploadfile">
                      <div className="app">
                        <div className="parent">
                          <div className="file-upload">
                            {selectedFile1 ? (
                              <img
                                style={{ height: "45px" }}
                                src={csv}
                                alt="upload"
                              />
                            ) : (
                              <img
                                className="after_file_upload"
                                src={uploadImg}
                                alt="upload"
                              />
                            )}
                            {selectedFile1 ? (
                              <h3>{selectedFile1.name}</h3>
                            ) : (
                              <h3>Click box to upload</h3>
                            )}
                            <input
                              type="file"
                              style={{ color: "var(--Whiteblack-color)" }}
                              name="file"
                              onChange={(e) => {
                                setSelectedFile1(e.target.files[0]);
                              }}
                              accept=".csv"
                              ref={bulk_guest_ref}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-buttons">
                    <button onClick={submit_bulk_file} className="cusbtn">
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          )}
          {/* pop up for upload file*/}

          <div className="card">
            <div
              className="department tablebox"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <div style={{ display: "flex" }}>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
                  <button
                    style={{
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                    className="cusbtn"
                  >
                    <BiUpload
                      size="1.5em"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    Email Invite
                  </button>

                  <button
                    style={{
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setBulk_guest(!bulk_guest);
                    }}
                    className="cusbtn"
                  >
                    <BiUpload
                      size="1.5em"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    Guest Upload (Bulk)
                  </button>

                  <button
                    onClick={() => {
                      setCreate_event_modal(!Create_event_modal);
                    }}
                    className="cusbtn"
                  >
                    <BiPlusMedical size="1.2rem" />
                  </button>
                </div>
              </div>
              {/* pagination */}

              <table className="customertable" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {/* {flight_data.GUEST_TABLE_DATA ? (
                    <> */}
                      {page.length === 0 ? (
                        <tr>
                          <td>No data</td>
                        </tr>
                      ) : (
                        page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    style={{
                                      textalign: "center",
                                    }}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                      )}
                    {/* </>
                  ) : (
                    ""
                  )} */}
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Guest;
