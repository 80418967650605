import React from "react";

const SideBar = ({ children }) => {

  return (
    <>

        <main>{children}</main>
     
    </>
  );
};

export default SideBar;
