import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { CgCloseR } from "react-icons/cg";
import { BiLeftArrow } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Assign from "./Assign";
import FormData from "form-data";
import axios from "axios";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SETEVENTAPI } from "../../redux/bulkbtns/Actions";
import { MultiSelect } from "react-multi-select-component";
import dp from "../../components/photos/dp.jpg";
import MOCK_DATA from "./Customers.json";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import Footer from "../Footer";

const Event = () => {
  const navigate = useNavigate();

  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ", flight_data.EVENT_TABLE_DATA);

  const [upcoming, setUpcoming] = useState(false);
  const [options, setOptions] = useState();

  useEffect(() => {
    event_table_detail();

    if (flight_data.EVENT_TABLE_DATA) {
      setUpcoming(true);
    }
    assistance_list();
  }, [upcoming]);

  // assistance list api
  async function assistance_list() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_assistance.php")
        .then((response) => {
          // console.log("event assign", response.data.data);

          // chnage key name here
          function selectKeyValuesFromArray(array, keyMapping) {
            const selectedArray = [];

            // Iterate over the array of objects
            for (let i = 0; i < array.length; i++) {
              const originalObject = array[i];
              const selectedObject = {};

              // Create a new object with the desired key-value pairs
              for (const originalKey in keyMapping) {
                if (originalObject.hasOwnProperty(originalKey)) {
                  const selectedKey = keyMapping[originalKey];
                  selectedObject[selectedKey] = originalObject[originalKey];
                }
              }

              // Push the selected object into the new array
              selectedArray.push(selectedObject);
            }

            return selectedArray;
          }

          const a = selectKeyValuesFromArray(response.data.data, {
            name: "label",
            id: "value",
          });
          setOptions(a);
        });
    } catch (e) {
      console.log(e);
    }
  }
  // assistance list api

  // event table api
  async function event_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_events.php")
        .then((response) => {
          // console.log(response)
          if (response.data.status == true) {
            dispatch(SETEVENTAPI(response.data.data));
          } else {
            dispatch(SETEVENTAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETEVENTAPI([]));

      console.log(e);
    }
  }

  // event table api

  const data = useMemo(() => {
    if (upcoming) {
      return flight_data.EVENT_TABLE_DATA;
    } else {
      return [];
    }
  }, [upcoming, flight_data.EVENT_TABLE_DATA]);
  // useEffect

  // Create Event Popup submit Start
  const signUpSchema = Yup.object().shape({
    eimage: Yup.mixed()
      .required("Select Image")
      .test("fileSize", "Max Size 2MB", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
    ename: Yup.string()
      .required("Enter Event Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets"),
    select_assistance: Yup.array().min(1, "Select at least one option"),
    startdate: Yup.date().required("Enter Start Date"),
    enddate: Yup.date().required("Enter End Date"),
    starttime: Yup.string().required("Enter Start Time"),
    endtime: Yup.string().required("Enter End Time"),
    venuename: Yup.string().min(1).required("Enter Venue Name"),
    venuelink: Yup.string().min(1).required("Enter Venue Link"),
    vimage: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
      if (file) {
        return file.size <= 2000000;
      } else {
        return true;
      }
    }),
    agenda: Yup.mixed()
      .required("Select Image")
      .test("fileSize", "Max Size 2MB", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
    vaddress: Yup.string().min(1).required("Enter Venue Address"),
  });

  const [initialValues, setinitialValues] = useState({
    eimage: "",
    select_assistance: [],
    ename: "",
    startdate: "",
    enddate: "",
    starttime: "",
    endtime: "",
    edesicption: "",
    venuename: "",
    venuelink: "",
    vimage: "",
    agenda: "",
    latitude: "",
    longitude: "",
    vaddress: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      submit_event_form(values, { value: "id" });
      // event_form(values);
      // alert("form Submitted");
      action.resetForm();
    },
  });

  // event api
  async function submit_event_form(values, keyMapping) {
    const selectedArray = [];

    // Iterate over the array of objects
    for (let i = 0; i < values.select_assistance.length; i++) {
      const originalObject = values.select_assistance[i];
      const selectedObject = {};

      // Create a new object with the desired key-value pairs
      for (const originalKey in keyMapping) {
        if (originalObject.hasOwnProperty(originalKey)) {
          const selectedKey = keyMapping[originalKey];
          selectedObject[selectedKey] = originalObject[originalKey];
        }
      }

      // Push the selected object into the new array
      selectedArray.push(selectedObject);
    }
    // flight api
    console.log("on submit =", selectedArray);

    event_form(values, selectedArray);
    async function event_form(e, selectedArray) {
      console.log("select ass =", selectedArray);
      const a = selectedArray.map((obj) => obj.id);
      console.log("select ass join=", a);
      var dataa = new FormData();
      dataa.append("event", e.eimage);
      dataa.append("select_assistance[]", a);
      dataa.append("venue", e.vimage);
      dataa.append("agenda", e.agenda);
      dataa.append("events_name", e.ename);
      dataa.append("description", e.edesicption);
      dataa.append("event_start_time", e.starttime);
      dataa.append("events_start_date", e.startdate);
      dataa.append("event_end_time", e.endtime);
      dataa.append("events_end_date", e.enddate);
      dataa.append("venue_name", e.venuename);
      dataa.append("venue_link", e.venuelink);
      dataa.append("latitude", e.latitude);
      dataa.append("longitude", e.longitude);
      dataa.append("venue_address", e.vaddress);
      // console.log(dataa);
      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/events.php", dataa)
          .then((response) => {
            console.log(response);
            if (response.data.status == true) {
              alert("Data uploaded successfully");
              setCreate_event_modal(!Create_event_modal);
              window.location.reload();
            } else {
              alert("File not upload");
            }
          });
      } catch (e) {
        console.log(e);
        alert("File not upload");
      }
    }
  }

  // event api

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);

  // Create Event

  const COLUMNS = [
    {
      Header: "Event Image",
      accessor: "event_image",
      Cell: ({ row }) => (
        <>
          <div
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              marginLeft: "33px",
              cursor: "pointer",
            }}
          >
            <img
              src={row.original.event_image}
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                objectFit: "cover",
                margin: "0px 5px 0px 5px",
              }}
              alt=""
            />
          </div>
        </>
      ),
    },
    {
      Header: "Event Name",
      accessor: "events_name",

      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            {row.original.events_name}
          </p>
        </>
      ),
    },
    {
      Header: "Date",
      accessor: (row) => {
        return [`${row.events_start_date}`, `${row.events_end_date}`];
      },
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/Event/Event_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            {row.original.events_start_date} to {row.original.events_end_date}
          </p>
        </>
      ),
    },
    {
      Header: "Time",
      accessor: (row) => {
        return [`${row.event_start_time}`, `${row.event_end_time}`];
      },
      Cell: ({ row }) => (
        <>
          <p>
            {row.original.event_start_time} to {row.original.event_end_time}
          </p>
        </>
      ),
    },
    {
      Header: "Event Description",
      accessor: "description",
      Cell: ({ row }) => (
        <>
          <p>{row.original.description}</p>
        </>
      ),
    },
    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <>
          <Assign id={row.original.id} />
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div className="main-container">
        <MainSidebar />
        <div className="allcontent">
          <Navbar />
          {/* <Breadcrumbs/> */}
          <div className="mainbox">
            {/* pop up for Create Event event*/}
            {Create_event_modal && (
              <div className="modal">
                <div
                  onClick={() => {
                    setCreate_event_modal(!Create_event_modal);
                  }}
                  className="overlay"
                ></div>
                <div className="modal-content">
                  <div className="modal-header">
                    <h2>Create Event</h2>
                    <button
                      className="close-modal"
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                      }}
                    >
                      <CgCloseR />
                    </button>
                  </div>
                  <div style={{ minHeight: "380px" }} className="modal-body">
                    <form onSubmit={handleSubmit}>
                      <h2
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Event Details
                      </h2>
                      <div className="input-block">
                        <label htmlFor="eimage" className="input-label">
                          Upload Event Image
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="file"
                          name="eimage"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => {
                            setFieldValue("eimage", e.currentTarget.files[0]);
                          }}
                          onBlur={handleBlur}
                        />
                        {touched.eimage && errors.eimage ? (
                          <p className="form-error">{errors.eimage}</p>
                        ) : null}
                      </div>

                      <div className="input-block">
                        <label
                          htmlFor="select_assistance"
                          className="input-label"
                        >
                          Select Assistance
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <MultiSelect
                          options={options ? options : ""}
                          name="select_assistance"
                          value={values.select_assistance}
                          onChange={(e) => {
                            setFieldValue("select_assistance", e);
                          }}
                          labelledBy="Select"
                        />
                        {errors.select_assistance &&
                        touched.select_assistance ? (
                          <p className="form-error">
                            {errors.select_assistance}
                          </p>
                        ) : null}
                      </div>

                      <div className="input-block">
                        <label htmlFor="ename" className="input-label">
                          Event Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="ename"
                          id="ename"
                          placeholder="Event Name"
                          value={values.ename}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.ename && errors.ename ? (
                          <p className="form-error">{errors.ename}</p>
                        ) : null}
                      </div>
                      <div className="input_box">
                        <div
                          className="input-block"
                          style={{ marginRight: "10px" }}
                        >
                          <label htmlFor="startdate" className="input-label">
                            Start Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="custominput"
                            autoComplete="off"
                            name="startdate"
                            id="startdate"
                            placeholder="startdate"
                            value={values.startdate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.startdate && touched.startdate ? (
                            <p className="form-error">{errors.startdate}</p>
                          ) : null}
                        </div>
                        <div className="input-block">
                          <label htmlFor="enddate" className="input-label">
                            End Date<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className="custominput"
                            autoComplete="off"
                            name="enddate"
                            id="enddate"
                            placeholder="End Date"
                            value={values.enddate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.enddate && touched.enddate ? (
                            <p className="form-error">{errors.enddate}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="input_box">
                        <div
                          className="input-block"
                          style={{ marginRight: "10px" }}
                        >
                          <label htmlFor="starttime" className="input-label">
                            Start Time <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="custominput"
                            autoComplete="off"
                            name="starttime"
                            id="starttime"
                            placeholder="Start Time"
                            value={values.starttime}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.starttime && touched.starttime ? (
                            <p className="form-error">{errors.starttime}</p>
                          ) : null}
                        </div>
                        <div className="input-block">
                          <label htmlFor="endtime" className="input-label">
                            End Time <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="custominput"
                            autoComplete="off"
                            name="endtime"
                            id="endtime"
                            placeholder="End Time"
                            value={values.endtime}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.endtime && touched.endtime ? (
                            <p className="form-error">{errors.endtime}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="input-block">
                        <label htmlFor="edesicption" className="input-label">
                          Event Desicption
                        </label>
                        <textarea
                          className="custominput"
                          name="edesicption"
                          value={values.edesicption}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Event Desicption"
                        />
                      </div>

                      <h2
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "14px",
                        }}
                      >
                        Venue Details
                      </h2>
                      <div className="input_box">
                        <div className="input-block">
                          <label htmlFor="vimage" className="input-label">
                            Upload Venue Image
                          </label>
                          <input
                            type="file"
                            name="vimage"
                            accept=".png,.jpg,.jpeg"
                            onChange={(e) => {
                              setFieldValue("vimage", e.currentTarget.files[0]);
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.vimage && errors.vimage ? (
                            <p className="form-error">{errors.vimage}</p>
                          ) : null}
                        </div>

                        <div className="input-block">
                          <label htmlFor="agenda" className="input-label">
                            Upload Agenda{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="file"
                            name="agenda"
                            accept=".png,.jpg,.jpeg"
                            onChange={(e) => {
                              setFieldValue("agenda", e.currentTarget.files[0]);
                            }}
                            onBlur={handleBlur}
                          />
                          {touched.agenda && errors.agenda ? (
                            <p className="form-error">{errors.agenda}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="input_box">
                        <div
                          className="input-block"
                          style={{ marginRight: "10px" }}
                        >
                          <label htmlFor="venuename" className="input-label">
                            Venue Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="name"
                            className="custominput"
                            autoComplete="off"
                            name="venuename"
                            id="venuename"
                            placeholder="Venue Name"
                            value={values.venuename}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.venuename && touched.venuename ? (
                            <p className="form-error">{errors.venuename}</p>
                          ) : null}
                        </div>
                        <div className="input-block">
                          <label htmlFor="venuelink" className="input-label">
                            Venue Link<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="name"
                            className="custominput"
                            autoComplete="off"
                            name="venuelink"
                            id="venuelink"
                            placeholder="Venue Link"
                            value={values.venuelink}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.venuelink && touched.venuelink ? (
                            <p className="form-error">{errors.venuelink}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="input_box">
                        <div
                          className="input-block"
                          style={{ marginRight: "10px" }}
                        >
                          <label htmlFor="latitude" className="input-label">
                            Latitude
                          </label>
                          <input
                            type="number"
                            className="custominput"
                            autoComplete="off"
                            name="latitude"
                            id="latitude"
                            placeholder="Latitude"
                            value={values.latitude}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.latitude && touched.latitude ? (
                            <p className="form-error">{errors.latitude}</p>
                          ) : null}
                        </div>
                        <div className="input-block">
                          <label htmlFor="longitude" className="input-label">
                            Longitude
                          </label>
                          <input
                            type="number"
                            className="custominput"
                            autoComplete="off"
                            name="longitude"
                            id="longitude"
                            placeholder="Longitude"
                            value={values.longitude}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.longitude && touched.longitude ? (
                            <p className="form-error">{errors.longitude}</p>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="vaddress" className="input-label">
                          Venue Address<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="vaddress"
                          id="vaddress"
                          placeholder="Venue Address"
                          value={values.vaddress}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.vaddress && touched.vaddress ? (
                          <p className="form-error">{errors.vaddress}</p>
                        ) : null}
                      </div>
                      <div className="modal-buttons">
                        <button className="cusbtn" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* pop up for Create Event event*/}
            <div className="card">
              <div
                className="department tablebox"
                style={{ backgroundcolor: "white" }}
              >
                {/* pagination */}
                <div className="pages">
                  <div className="tablebottomboxleft">
                    <h1>Show</h1>
                    <span>
                      <select
                        className="selectnoofpagescolor"
                        style={{
                          padding: "4px 10px 4px 3px",
                          borderRadius: "10px",
                        }}
                        value={pageSize}
                        // className="showrows"
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {[10, 15, 20].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>

                  <button
                    onClick={() => {
                      setCreate_event_modal(!Create_event_modal);
                    }}
                    className="cusbtn"
                  >
                    <BiPlusMedical size="1.2rem" />
                  </button>
                </div>
                {/* pagination */}

                <table className="customertable" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroups) => (
                      <tr {...headerGroups.getHeaderGroupProps()}>
                        {headerGroups.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? "🔽"
                                  : "🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {/* {flight_data.EVENT_TABLE_DATA ? (
                      <> */}
                        {page.length === 0 ? (
                          <tr>
                            <td>No data</td>
                          </tr>
                        ) : (
                          page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td
                                      style={{
                                        textalign: "center",
                                      }}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })
                        )}
                      {/* </>
                    ) : (
                      ""
                    )} */}
                  </tbody>
                </table>
                <div className="pagination">
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <button
                    className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <BiLeftArrow />
                  </button>
                  <button
                    className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <BiRightArrow />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
};

export default Event;
