import React, { useState } from "react";
import Navbar from "../Navbar";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { useFormik } from "formik";
import { HiPencilAlt } from "react-icons/hi";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import profilepic from "../../components/photos/dp.jpg";

const Profile = () => {
  const [SelectValue, setSelectValue] = useState(false);
  const [editInput, setEditInput] = useState(true);
  const navigate = useNavigate();

  const initialValues = {
    name: "Zatin",
    email: "abc@gmail.com",
    phone: "9877996624",
    role: "Niggar",
    address2: "Dhobi ghat",
    gender: "kabir",
  };

  const signUpSchema = Yup.object({
    name: Yup.string()
      .min(2, "This is a required field")
      .required("This is a required field"),

    email: Yup.string()
      .email("Must be a valid email")
      .required("This is a required field"),

    phone: Yup.number(2, "This is a required field").required(
      "This is a required field"
    ),

    role: Yup.string()
      .min(1, "This is a required field")
      .required("This is a required field"),

    address2: Yup.string()
      .min(1, "This is a required field")
      .required("This is a required field"),

    gender: Yup.string()
      .min(1, "This is a required field")
      .required("This is a required field"),
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
        alert("form Submitted");
        action.resetForm();
      },
    });
  return (
    <div className="main-container">
      <MainSidebar />
      <div className="allcontent">
        <Navbar />
        <div className="mainbox  mt-5">
          <div className="card cardpadding">
            <div className="firstsecprofile"></div>

            <div className="inputdetails">
              <div className="profilephoto">
                <img src={profilepic} alt="" />
                <div className="penciledit">
                  <button onClick={() => {setEditInput(false)}} className="cusbtn">
                    <HiPencilAlt />
                    Edit
                  </button>
                </div>
              </div>
              <div className="profileheading">
                <h2 style={{ color: "var(--main-color)" }}>
                  Basic Information
                </h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="profileformbody">
                  <div className="inputerrbox">
                    <label className="form-labelprofile">Name</label>
                    <div className="input-blockprofile mb-1">
                      <input
                        className={ editInput ? "nocustominputprofile" :"custominputprofile"}
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                        readOnly={editInput}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div style={{ height: "20px" }}>
                        {errors.name && touched.name ? (
                          <p className="form-error">{errors.name}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="inputerrbox">
                    <label className="form-labelprofile">Email</label>
                    <div className="input-blockprofile mb-1">
                      <input
                        className={ editInput ? "nocustominputprofile" :"custominputprofile"}
                        type="email"
                        // autoComplete='on'
                        name="email"
                        id="email"
                        placeholder="Email"
                        readOnly={editInput}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <div style={{ height: "20px" }}>
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="inputerrbox">
                    <label className="form-labelprofile">Phone</label>
                    <div className="input-blockprofile mb-1">
                      <input
                        className={ editInput ? "nocustominputprofile" :"custominputprofile"}
                        type="tel"
                        name="phone"
                        readOnly={editInput}
                        id="phone"
                        placeholder="+91999090909"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <div style={{ height: "20px" }}>
                        {errors.phone && touched.phone ? (
                          <p className="form-error">{errors.phone}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="inputerrbox">
                    <label className="form-labelprofile">Role</label>
                    <div className="input-blockprofile mb-1">
                      <input
                        className={ editInput ? "nocustominputprofile" :"custominputprofile"}
                        type="text"
                        name="role"
                        id="address1"
                        readOnly={editInput}
                        placeholder="H-157 Sector 62"
                        value={values.role}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <div style={{ height: "20px" }}>
                        {errors.role && touched.role ? (
                          <p className="form-error">{errors.role}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="inputerrbox">
                    <label className="form-labelprofile">Address</label>
                    <div className="input-blockprofile mb-1">
                      <input
                        className={ editInput ? "nocustominputprofile" :"custominputprofile"}
                        type="text"
                        name="address2"
                        readOnly={editInput}
                        id="address2"
                        placeholder="Sector 62, noida"
                        value={values.address2}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <div style={{ height: "20px" }}>
                        {errors.address2 && touched.address2 ? (
                          <p className="form-error">{errors.address2}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="inputerrbox">
                    <label className="form-labelprofile">Gender</label>
                    <div className="input-blockprofile mb-1">
                      <input
                        className={ editInput ? "nocustominputprofile" :"custominputprofile"}
                        onFocus={() => {
                          setSelectValue(true);
                        }}
                        placeholder="gender"
                        name="gender"
                        readOnly={editInput}
                        value={values.gender}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div style={{ height: "20px" }}>
                        {errors.gender && touched.gender ? (
                          <p className="form-error">{errors.gender}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="customersubmit">
                  <button type="submit" className="cusbtn m-3">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
