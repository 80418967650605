import { NavLink } from "react-router-dom";
import { useState } from "react";
import {  FaHome } from "react-icons/fa";
import { FaFileInvoice } from "react-icons/fa";
import { BsFillPeopleFill } from "react-icons/bs";
import { MdViewModule } from "react-icons/md";
import { MdOutlinePayment } from "react-icons/md";
import { RiPagesFill } from "react-icons/ri";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { SETACTiVELINK } from "../../redux/bulkbtns/Actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { MdAdminPanelSettings } from "react-icons/md";
import { BiSearch } from "react-icons/bi";

const routes = [
  {
    path: "/Dashboard/Dashboard.jsx",
    name: "Dashboard",
    icon: <FaHome className="menuicon" />,
    toggleState: 1
  },
  {
    path: "/Event/Event.jsx",
    name: "Event",
    icon: <FaFileInvoice className="menuicon" />,
    toggleState: 2
  },
  {
    path: "/Guest/Guest.jsx",
    name: "Guest",
    icon: <BsFillPeopleFill className="menuicon" />,
    toggleState: 3
  },
  {
    path: "/Assistance/Assistance.jsx",
    name: "Assistance",
    icon: <MdViewModule className="menuicon" />,
    toggleState: 4
  },
  {
    path: "/Flight/Flight.jsx",
    name: "Flight",
    icon: <MdOutlinePayment className="menuicon" />,
    toggleState: 5
  },
  {
    path: "/Cab/Cab.jsx",
    name: "Cab",
    icon: <RiPagesFill className="menuicon" />,
    toggleState: 6
  },
];

const MainSidebar = ({ children }) => {

  const dispatch = useDispatch();
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    dispatch(SETACTiVELINK(index));
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const togglesidebar = useSelector((state) => state.Reducer);
  // console.log(togglesidebar.VALUE_ACTIVE_LINK)

  const showAnimation = {
    // hidden: {
    //   width: 0,
    //   opacity: 0,
    //   transition: {
    //     duration: 0.5,
    //   },
    // },
    // show: {
    //   opacity: 1,
    //   width: "auto",
    //   transition: {
    //     duration: 0.5,
    //   },
    // },
  };

  return (
    <>
      {/* <div className="main-container"> */}
        <motion.div
          animate={{
            width: togglesidebar.SIDEMENUOPENCLOSE ? "200px" : "45px",
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 13,
            },
          }}
          className={`sidebar`}
        >
          <div className="top_section">
            <AnimatePresence>
              {togglesidebar.SIDEMENUOPENCLOSE && (
                <motion.img
                  src={require("../../components/photos/wizsuite.png")}
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo"
                />
              )}
              {!togglesidebar.SIDEMENUOPENCLOSE && (
                <motion.img
                  src={require("../../components/photos/wizlogo.png")}
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="wizlogo"
                />
              )}
            </AnimatePresence>
          </div>

          <section className="routes">
            {routes.map((route, index) => {
              if (route.subRoutes) {
                return (
                  <SidebarMenu
                    setIsOpen={setIsOpen}
                    route={route}
                    showAnimation={showAnimation}
                    isOpen={togglesidebar.SIDEMENUOPENCLOSE}
                  />
                );
              }

              return (
                <NavLink
                  to={route.path}
                  key={index}
                  // className="link"
                  // activeClassName="active"
                  className={ togglesidebar.VALUE_ACTIVE_LINK == route.toggleState ? "linkactive " : "link"}
                  onClick={() => toggleTab(route.toggleState)}
                >
                  <div className="icon">{route.icon}</div>
                  <AnimatePresence>
                    {togglesidebar.SIDEMENUOPENCLOSE && (
                      <motion.div
                        variants={showAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="link_text"
                      >
                        {route.name}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </NavLink>
              );
            })}
          </section>
        </motion.div>
      {/* </div> */}
    </>
  );
};

export default MainSidebar;
