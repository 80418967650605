import React, { useState, useMemo } from "react";
import Navbar from "../Navbar";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { HiPencilAlt } from "react-icons/hi";
import profilepic from "../../components/photos/dp.jpg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Upcoming_Event from "./Upcoming_Event.jsx";
import Old_Event from "./Old_Event.jsx";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { SETUPCOMINGGUEST } from "../../redux/bulkbtns/Actions";
import { SETOLDGUEST } from "../../redux/bulkbtns/Actions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import pdf from "../../components/photos/pdfthumbnail.png";

const Event_Details = () => {
  const [editInput, setEditInput] = useState(true);
  const [show_submit_btn, set_Show_submit_btn] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("state = ", props.data);

  const location = useLocation();
  const name = location.state.name;
  // console.log("name=", name.id);

  const [upcoming, setUpcoming] = useState(false);
  const [upcomingtable, setUpcomingtable] = useState();
  const [oldevent, setOldevent] = useState(false);
  useEffect(() => {
    upcoming_guest_table_detail();

    if (flight_data.UPCOMING_GUEST_TABLE_DATA) {
      setUpcoming(true);
    }

    old_guest_table_detail();

    if (flight_data.OLD_GUEST_TABLE_DATA) {
      setOldevent(true);
    }
    
  }, [upcoming, oldevent]);

  // old guest table api

  async function old_guest_table_detail() {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/oldeventsapi.php", {
          user_id: name.id,
        })
        .then((response) => {
          // console.log("old =",response.data);
          if (response.data.status == true) {
            dispatch(SETOLDGUEST(response.data.data));
          } else {
            dispatch(SETOLDGUEST([]));
          }
        });
    } catch (e) {
      dispatch(SETOLDGUEST([]));
      console.log(e);
    }
  }
  // old guest table api

  // upcoming guest table api

  async function upcoming_guest_table_detail() {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/upcomingapi.php", {
          user_id: name.id,
        })
        .then((response) => {
          // console.log("up =",response.data);
          if (response.data.status) {
            dispatch(SETUPCOMINGGUEST(response.data.data));
            setUpcomingtable(response.data.data);
          } else {
            dispatch(SETUPCOMINGGUEST([]));
            setUpcomingtable([]);
          }
        });
    } catch (e) {
      setUpcomingtable([]);
      dispatch(SETUPCOMINGGUEST([]));
      console.log(e);
    }
  }
  // upcoming guest table api

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const options = [
    { label: "one", value: 1, disabled: true },
    { label: "two", value: 2 },
  ];

  const [initialValues, setinitialValues] = useState({
    name: name.name,
    phone: name.phone,
    email: name.email,
    city: name.city,
    t_shirt: name.t_shirt,
    govt_id: name.govermt_id_type,
    uploadgovt_id: "",
  });

  const signUpSchema = Yup.object({
    name: Yup.string().required("Enter Name"),
    phone: Yup.string()
      .required("Enter Phone Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    email: Yup.string().required("Enter Email").email("Enter valid Email"),
    city: Yup.string().required("Enter City"),
    t_shirt: Yup.string().required("Enter T-Shirt Size"),
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      guest_form(values);
      alert("form Submitted");
      action.resetForm();
    },
  });

  // guest api
  async function guest_form(e) {
    var dataa = new FormData();
    dataa.append("id", name.id);
    dataa.append("name", e.name);
    dataa.append("phone", e.phone);
    dataa.append("email", e.email);
    dataa.append("city", e.city);
    dataa.append("t_shirt", e.t_shirt);

    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/updateguest.php", dataa)
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == true) {
            alert("Successfully Updated");
          } else {
            alert(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // guest api

  return (
    <>
      <div className="main-container">
        <MainSidebar />
        <div className="allcontent">
          <Navbar />
          {/* <Breadcrumbs/> */}
          <div className="mainbox">
            <div className="details_edit">
              <button
                className="cusbtn"
                onClick={() => {
                  setEditInput(!editInput);
                  set_Show_submit_btn(!show_submit_btn);
                }}
              >
                <HiPencilAlt /> Edit
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="Detils_section">
                <div className="card details_img_box">
                  <div className="details_img">
                    <div className="detailsprofilephoto">
                      {/* {
                        name.govermt_id_photo ?
                        <Document file={name.govermt_id_photo}>
                        <Page pageNumber={1} width={100} />
                      </Document>:  <div>Loading PDF Thumbnail...</div>} */}
                     

                   
                      <img src={pdf} alt="" /> 
                    </div>
                  </div>
                </div>
                <div className="card details_box">
                  <div className="details_box_sec">
                    <h2>Guest Details</h2>
                    <div
                      className="input-block"
                      style={{ marginRight: "10px" }}
                    >
                      <label htmlFor="name" className="input-label">
                        name<span>*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          editInput
                            ? "nocustominputprofile"
                            : "custominputprofile"
                        }
                        autoComplete="off"
                        name="name"
                        id="name"
                        readOnly={editInput}
                        placeholder="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.name && touched.name ? (
                        <p className="form-error">{errors.name}</p>
                      ) : null}
                    </div>
                    <div className="details_inputbox">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="phone" className="input-label">
                          Phone<span>*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="phone"
                          id="phone"
                          readOnly={editInput}
                          placeholder="End Date"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p className="form-error">{errors.phone}</p>
                        ) : null}
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="email" className="input-label">
                          email<span>*</span>
                        </label>
                        <input
                          type="email"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="email"
                          id="email"
                          readOnly={editInput}
                          placeholder="Start Time"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.email && touched.email ? (
                          <p className="form-error">{errors.email}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="details_inputbox">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="city" className="input-label">
                          city<span>*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          autoComplete="off"
                          name="city"
                          id="city"
                          readOnly={editInput}
                          placeholder="city"
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.city && touched.city ? (
                          <p className="form-error">{errors.city}</p>
                        ) : null}
                      </div>
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="t_shirt" className="input-label">
                          T-Shirt<span>*</span>
                        </label>
                        <select
                          type="t_shirt"
                          className={
                            editInput
                              ? "nocustominputprofile"
                              : "custominputprofile"
                          }
                          readOnly={editInput}
                          autoComplete="off"
                          name="t_shirt"
                          id="t_shirt"
                          placeholder="T-Shirt"
                          value={values.t_shirt}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <option disabled value="">
                            Select Size
                          </option>
                          <option value="XS">XS</option>
                          <option value="S">S</option>
                          <option value="M">M</option>
                          <option value="L">L</option>
                          <option value="XL">XL</option>
                          <option value="XXL">XXL</option>
                          <option value="3XL">3XL</option>
                        </select>
                        {errors.t_shirt && touched.t_shirt ? (
                          <p className="form-error">{errors.t_shirt}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="modal-buttons">
                      {show_submit_btn && (
                        <button className="cusbtn" type="submit">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="container">
              <div className="bloc-tabs">
                <button
                  className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(1)}
                >
                  Upcoming Events
                </button>
                <button
                  className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  Old Events
                </button>
              </div>

              <div className="content-tabs">
                <div
                  className={
                    toggleState === 1 ? "content  active-content" : "content"
                  }
                >
                  <Upcoming_Event data={upcoming} table={upcomingtable} />
                </div>

                <div
                  className={
                    toggleState === 2 ? "content  active-content" : "content"
                  }
                >
                  <Old_Event data={oldevent} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Event_Details;
