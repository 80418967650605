import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { AiOutlineClose } from "react-icons/ai";
import { BiLeftArrow } from "react-icons/bi";
import { BiPlusMedical, BiUpload } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import uploadImg from "../../components/photos/uploadimg.png";
import csv from "../../components/photos/csv.png";
import { SETCABAPI } from "../../redux/bulkbtns/Actions";
import { CgCloseR } from "react-icons/cg";
import cab_details from "../../cab_details.csv";
import Assign from "./Assign";
import MOCK_DATA from "./Customers.json";
import dp from "../../components/photos/dp.jpg";
import { MultiSelect } from "react-multi-select-component";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import Footer from "../Footer";

const Cab = () => {
  const navigate = useNavigate();

  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ",flight_data.CAB_TABLE_DATA)

  const [upcoming, setUpcoming] = useState(false);
  useEffect(() => {
    cab_table_detail();
    if (flight_data.CAB_TABLE_DATA) {
      setUpcoming(true);
    }
  }, [upcoming]);

  // cab table api
  async function cab_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_cab.php")
        .then((response) => {
          if (response.data.status == true) {
            dispatch(SETCABAPI(response.data.data));
          } else {
            dispatch(SETCABAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETCABAPI([]));
      console.log(e);
    }
  }

  const data = useMemo(() => {
    if (upcoming) {
      return flight_data.CAB_TABLE_DATA;
    } else {
      return [];
    }
  }, [upcoming, flight_data.CAB_TABLE_DATA]);
  // cab table api
  // useEffect

  // upload file
  const [uploadfile, setUploadfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [select_event_upload, setSelect_event_upload] = useState();

  const fileInputRef = useRef(null);

  const submitfile = (e) => {
    e.preventDefault();
    const files = fileInputRef.current.files;
    console.log(files);
    if (select_event_upload == "" || select_event_upload == undefined) {
      alert("Select Event");
      return;
    }

    if (files.length === 0) {
      alert("Select File");
      return;
    }
    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("file", files[0]);
      dataa.append("event_id", select_event_upload);

      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/uploadcabfile.php", dataa)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == true) {
              alert("Successfully Uploaded");
              setUploadfile(!uploadfile);
              window.location.reload();
            } else {
              alert(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // upload file

  // Create Event Popup submit Start
  const signUpSchema = Yup.object({
    select_cab: Yup.string().required("Enter Event"),
    cab_number: Yup.string().required("Enter Cab Number"),
    driver_name: Yup.string()
      .required("Enter Driver Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets"),
    driver_contact: Yup.string()
      .required("Enter Contact Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),

    driver_whatsapp: Yup.string()
      .required("Enter Whatsapp Number")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Enter your 10 Digit number")
      .max(10, "Enter your 10 Digit number"),
    pickup_point: Yup.string().required("Enter Pickup Point"),
    drop_point: Yup.string().required("Enter Drop Point"),
    pickup_date: Yup.date().required("Enter Pickup Date"),
    pickup_time: Yup.string().required("Enter Pickup Time"),
    select_cab_type: Yup.string().required("Enter Cab Type"),
  });

  const [initialValues, setinitialValues] = useState({
    select_cab: "",
    cab_number: "",
    driver_name: "",
    driver_contact: "",
    driver_whatsapp: "",
    pickup_point: "",
    drop_point: "",
    pickup_date: "",
    pickup_time: "",
    cab_description: "",
    select_cab_type: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      cabform(values);
      action.resetForm();
    },
  });
  // cab api
  async function cabform(e) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/cab.php", {
          events_id: e.select_cab,
          cab_no: e.cab_number,
          driver_name: e.driver_name,
          driver_contact_no: e.driver_contact,
          driver_whatsapp_no: e.driver_whatsapp,
          pickup_point: e.pickup_point,
          pick_up_date: e.pickup_date,
          pickup_time: e.pickup_time,
          drop_point: e.drop_point,
          drop_time: e.drop_time,
          description: e.cab_description,
          status: e.select_cab_type,
        })
        .then((response) => {
          if (response.data.status == true) {
            alert("Data uploaded successfully");
            setCreate_event_modal(!Create_event_modal);
            window.location.reload();
          } else {
            alert(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // cab api

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);

  // Create Event

  const COLUMNS = [
    {
      Header: "Cab Number",
      accessor: "cab_no",
      // accessor: (row) => {return ([`${row.name}`, `${row.email}`])} ,
      Cell: ({ row }) => (
        <>
          <p>{row.original.cab_no}</p>
        </>
      ),
    },
    {
      Header: "Driver Name",
      accessor: "driver_name",
      Cell: ({ row }) => (
        <>
          <p>{row.original.driver_name}</p>
        </>
      ),
    },
    {
      Header: "Driver Contact",
      accessor: "driver_contact_no",
      Cell: ({ row }) => (
        <>
          <p>{row.original.driver_contact_no}</p>
        </>
      ),
    },
    {
      Header: "Driver WhatsApp",
      accessor: "driver_whatsapp_no",
      Cell: ({ row }) => (
        <>
          <p>{row.original.driver_whatsapp_no}</p>
        </>
      ),
    },
    {
      Header: "Pick up point",
      accessor: "pickup_point",
      Cell: ({ row }) => (
        <>
          <p>{row.original.pickup_point}</p>
        </>
      ),
    },
    {
      Header: "Drop Point",
      accessor: "drop_point",
      Cell: ({ row }) => (
        <>
          <p>{row.original.drop_point}</p>
        </>
      ),
    },
    {
      Header: "Pickup date",
      accessor: "pick_up_date",
      Cell: ({ row }) => (
        <>
          <p>{row.original.pick_up_date}</p>
        </>
      ),
    },
    {
      Header: "pickup time",
      accessor: "pickup_time",
      Cell: ({ row }) => (
        <>
          <p>{row.original.pickup_time}</p>
        </>
      ),
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ row }) => (
        <>
          <p>{row.original.description}</p>
        </>
      ),
    },
    // {
    //   Header: "Action",
    //   accessor: "modifiedtime",
    //   Cell: ({ row }) => (
    //     <>
    //       <Assign />

    //     </>
    //   ),
    // },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="main-container">
      <MainSidebar />
      <div className="allcontent">
        <Navbar />
        {/* <Breadcrumbs/> */}
        <div className="mainbox">
          {/* pop up for Create Event event*/}
          {Create_event_modal && (
            <div className="modal">
              <div
                onClick={() => {
                  setCreate_event_modal(!Create_event_modal);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create Cab</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setCreate_event_modal(!Create_event_modal);
                    }}
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                <div style={{ minHeight: "380px" }} className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="input-block">
                      <label htmlFor="select_cab" className="input-label">
                        Select Event <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="select_cab"
                        id="select_cab"
                        value={values.select_cab}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Event</option>
                        {flight_data.EVENT_TABLE_DATA.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.events_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {errors.select_cab && touched.select_cab ? (
                        <p className="form-error">{errors.select_cab}</p>
                      ) : null}
                    </div>

                    <div className="input-block">
                      <label
                        htmlFor="select_flight_type"
                        className="input-label"
                      >
                        Cab Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="select_cab_type"
                        id="select_cab_type"
                        value={values.select_cab_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled value="">
                          Select Cab Type
                        </option>
                        <option value="From Airport">To Event Journey</option>
                        <option value="To Airport">Return Journey</option>
                      </select>
                      {errors.select_cab_type && touched.select_cab_type ? (
                        <p className="form-error">{errors.select_cab_type}</p>
                      ) : null}
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="cab_number" className="input-label">
                          Cab Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="cab_number"
                          id="cab_number"
                          placeholder="Cab Number"
                          value={values.cab_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.cab_number && errors.cab_number ? (
                          <p className="form-error">{errors.cab_number}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="driver_name" className="input-label">
                          Driver Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="driver_name"
                          id="driver_name"
                          placeholder="Driver Name"
                          value={values.driver_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.driver_name && touched.driver_name ? (
                          <p className="form-error">{errors.driver_name}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="driver_contact" className="input-label">
                          Driver Contact <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          pattern="[0-9]*"
                          className="custominput"
                          autoComplete="off"
                          maxLength={10}
                          minLength="10"
                          name="driver_contact"
                          id="driver_contact"
                          placeholder="xxxxxxxxxx"
                          value={values.driver_contact}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.driver_contact && touched.driver_contact ? (
                          <p className="form-error">{errors.driver_contact}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label
                          htmlFor="driver_whatsapp"
                          className="input-label"
                        >
                          Driver WhatsApp{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="custominput"
                          pattern="[0-9]*"
                          maxLength={10}
                          minLength="10"
                          autoComplete="off"
                          name="driver_whatsapp"
                          id="driver_whatsapp"
                          placeholder="xxxxxxxxxx"
                          value={values.driver_whatsapp}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.driver_whatsapp && touched.driver_whatsapp ? (
                          <p className="form-error">{errors.driver_whatsapp}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="pickup_point" className="input-label">
                          Pick up point <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="pickup_point"
                          id="pickup_point"
                          placeholder="Pick up point"
                          value={values.pickup_point}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.pickup_point && touched.pickup_point ? (
                          <p className="form-error">{errors.pickup_point}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="drop_point" className="input-label">
                          Drop Point <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="drop_point"
                          id="drop_point"
                          placeholder="Drop Point"
                          value={values.drop_point}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.drop_point && touched.drop_point ? (
                          <p className="form-error">{errors.drop_point}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="pickup_date" className="input-label">
                          Pickup date <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          className="custominput"
                          autoComplete="off"
                          name="pickup_date"
                          id="pickup_date"
                          placeholder="Pickup Date"
                          value={values.pickup_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.pickup_date && touched.pickup_date ? (
                          <p className="form-error">{errors.pickup_date}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="pickup_time" className="input-label">
                          Pickup Time <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="time"
                          className="custominput"
                          autoComplete="off"
                          name="pickup_time"
                          id="pickup_time"
                          placeholder="Pickup Time"
                          value={values.pickup_time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.pickup_time && touched.pickup_time ? (
                          <p className="form-error">{errors.pickup_time}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-block">
                      <label htmlFor="cab_description" className="input-label">
                        Desicption
                      </label>
                      <textarea
                        className="custominput"
                        name="cab_description"
                        value={values.cab_description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Desicption"
                      />
                    </div>
                    <div className="modal-buttons">
                      <button className="cusbtn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* pop up for Create Event event*/}

          {/* pop up for upload file*/}
          {uploadfile && (
            <div className="modal">
              <div
                onClick={() => {
                  setUploadfile(!uploadfile);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2> Cab Upload (Bulk)</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  {/* <form  > */}

                  <div className="input-block">
                    <label htmlFor="select_event" className="input-label">
                      Select Event <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="custominput selectoption"
                      name="select_event"
                      id="select_event"
                      onChange={(e) => {
                        setSelect_event_upload(e.target.value);
                      }}
                    >
                      <option value="">Select Event</option>
                      {flight_data.EVENT_TABLE_DATA
                        ? flight_data.EVENT_TABLE_DATA.map((ele, index) => {
                            return (
                              <>
                                <option index={index} value={ele.id}>
                                  {ele.events_name}
                                </option>
                              </>
                            );
                          })
                        : ""}
                    </select>
                  </div>

                  <div className="download mt-2">
                    <a
                      href={cab_details}
                      download="Cab_sample.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="cusbtn">
                        Download sample.csv file
                      </button>
                    </a>
                  </div>

                  <div className="input-block">
                    <label htmlFor="uploademailfile" className="input-label">
                      Upload Cab Details <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="setuploadfile">
                      <div className="app">
                        <div className="parent">
                          <div className="file-upload">
                            {selectedFile ? (
                              <img style={{ height:"45px" }}  src={csv} alt="upload" />
                            ) : (
                              <img className="after_file_upload" src={uploadImg} alt="upload" />
                            )}

                            {selectedFile ? (
                              <h3>{selectedFile.name}</h3>
                            ) : (
                              <h3>Click box to upload</h3>
                            )}
                            <input
                              type="file"
                              style={{ color: "var(--Whiteblack-color)" }}
                              name="file"
                              onChange={(e) => {
                                setSelectedFile(e.target.files[0]);
                              }}
                              accept=".csv"
                              ref={fileInputRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-buttons">
                    <button onClick={submitfile} className="cusbtn">
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          )}
          {/* pop up for upload file*/}

          <div className="card">
            <div
              className="department tablebox"
              style={{ backgroundcolor: "white" }}
            >
              {/* pagination */}
              <div className="pages">
                <div className="tablebottomboxleft">
                  <h1>Show</h1>
                  <span>
                    <select
                      className="selectnoofpagescolor"
                      style={{
                        padding: "4px 10px 4px 3px",
                        borderRadius: "10px",
                      }}
                      value={pageSize}
                      // className="showrows"
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 15, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <div style={{ display: "flex" }}>
                  <button
                    style={{
                      marginRight: "5px",
                    }}
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                    className="cusbtn"
                  >
                    <BiUpload
                      size="1.5em"
                      style={{ verticalAlign: "middle" }}
                    />{" "}
                    Bulk Cab Upload
                  </button>

                  <button
                    onClick={() => {
                      setCreate_event_modal(!Create_event_modal);
                    }}
                    className="cusbtn"
                  >
                    <BiPlusMedical size="1.2rem" />
                  </button>
                </div>
              </div>
              {/* pagination */}

              <table className="customertable" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {/* {
                    flight_data.CAB_TABLE_DATA ?
                    <> */}
                    {page.length === 0 ? (
                      <tr>
                        <td>No data</td>
                      </tr>
                    ) : (
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  style={{
                                    textalign: "center",
                                  }}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    )} 
                    {/* </>:""
                  } */}
                  
                </tbody>
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Cab;
