import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Navbar from "../Navbar";
import { AiOutlineClose } from "react-icons/ai";
import { BiLeftArrow, BiUpload } from "react-icons/bi";
import { BiPlusMedical } from "react-icons/bi";
import axios from "axios";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SETFLIGHTAPI } from "../../redux/bulkbtns/Actions";
import { useSelector } from "react-redux";
import Flight_Details from "./Flight_Details";
import uploadImg from "../../components/photos/uploadimg.png";
import csv from "../../components/photos/csv.png";
import TruncatedText from './TruncatedText'; 
import { CgCloseR } from "react-icons/cg";
import flight_details from "../../flight_details.csv";
import Assign from "./Assign";
import MOCK_DATA from "./Customers.json";
// import { GlobalFilter_Customers } from "./GlobalFilter_Customers";
// import { COLUMNS } from "./Customers_Column";
import { MultiSelect } from "react-multi-select-component";
import dp from "../../components/photos/dp.jpg";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import Footer from "../Footer";

const Flight = () => {
  const navigate = useNavigate();

  // useEffect
  const dispatch = useDispatch();

  const flight_data = useSelector((state) => state.Reducer);
  // console.log("redux = ",flight_data.FLIGHT_TABLE_DATA)

  // upload file
  const [uploadfile, setUploadfile] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [select_event_upload, setSelect_event_upload] = useState();
  // const [csvfile, setCsvfile] = useState();
  const fileInputRef = useRef(null);

  const submitfile = (e) => {
    
    e.preventDefault();
    const files = fileInputRef.current.files;
    console.log(files);

    if(files.length === 0) {
      alert("Select File");
      console.log("file",files.length)
      return;
    }

  
  if (select_event_upload == "" || select_event_upload == undefined) {
      alert("Select Event");
     
      return;
    } 
  

    upload_csv();
    async function upload_csv() {
      console.log(files[0]);
      var dataa = new FormData();
      dataa.append("file", files[0]);
      dataa.append("event_id", select_event_upload);

      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/uploadflight.php", dataa)
          .then((response) => {
            console.log(response.data);
            if (response.data.status == true) {
              alert("Successfully Uploaded");
              setUploadfile(!uploadfile);
              window.location.reload();
            } else {
              alert(response.data.message);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  // upload file

  const [upcoming, setUpcoming] = useState(false);
  useEffect(() => {
    flight_table_detail();
    if (flight_data.FLIGHT_TABLE_DATA) {
      setUpcoming(true);
    }
  }, [upcoming]);

  // flight table api
  async function flight_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_flight.php")
        .then((response) => {
          // console.log(response.data.data);
          if (response.data.status == true) {
            dispatch(SETFLIGHTAPI(response.data.data));
          } else {
            dispatch(SETFLIGHTAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETFLIGHTAPI([]));
      console.log(e);
    }
  }

  const data = useMemo(() => {
    if (upcoming) {
      return flight_data.FLIGHT_TABLE_DATA;
    } else {
      return [];
    }
  }, [upcoming, flight_data.FLIGHT_TABLE_DATA]);
  // flight table api
  // useEffect

  // Create Event Popup submit Start
  const signUpSchema = Yup.object({
    select_event: Yup.string().required("Enter Event"),
    select_flight_type: Yup.string().required("Enter Flight Type"),
    flight_name: Yup.string()
      .required("Enter Flight Name")
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets and spaces"),
    flight_number: Yup.string().required("Enter Flight Number"),
    from_location: Yup.string().required("Enter Flight From Location"),
    to_location: Yup.string().required("Enter Flight To Location"),
    journey_date: Yup.date().required("Enter Journey Start Date"),
    journey_time: Yup.string().required("Enter Journey Start Time"),
    layover: Yup.string().min(2, "Enter layover"),
    pnr_no: Yup.string().required("Enter PNR Number"),
    check_in_link: Yup.string().required("Enter Check in Link"),
  });

  const [initialValues, setinitialValues] = useState({
    select_event: "",
    select_flight_type: "",
    flight_name: "",
    flight_number: "",
    from_location: "",
    to_location: "",
    journey_date: "",
    checkout_date: "",
    journey_time: "",
    checkout_time: "",
    pnr_no: "",
    layover: "",
    check_in_link: "",
    flight_desicption: "",
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      flightform(values);

      action.resetForm();
    },
  });

  // flight api
  async function flightform(e) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/flight.php", {
          events_id: e.select_event,
          from_location: e.from_location,
          to_location: e.to_location,
          checkout_date: e.checkout_date,
          checkout_time: e.checkout_time,
          checkin_date: e.journey_date,
          checkin_time: e.journey_time,
          flight_name: e.flight_name,
          flight_no: e.flight_number,
          layover: e.layover,
          pnr_no: e.pnr_no,
          description: e.flight_desicption,
          checkin_link: e.check_in_link,
          status: e.select_flight_type,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            alert("Data uploaded successfully");
            setCreate_event_modal(!Create_event_modal);
            window.location.reload();
          } else {
            alert(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // flight api

  // Create Event
  const [Create_event_modal, setCreate_event_modal] = useState(false);
  // Create Event

  
  const COLUMNS = [
    {
      Header: "Flight",
      accessor: "flight_name",
      // accessor: (row) => {return ([`${row.name}`, `${row.email}`])} ,
      Cell: ({ row }) => (
        <>
          <p style={{ fontWeight:"bold"}}>{row.original.flight_name}</p>
          <p style={{ fontSize:"12px"}}>{row.original.flight_no}</p>
        </>
      ),
    },
    // {
    //   Header: "Flight Number",
    //   accessor: "flight_no",
    //   Cell: ({ row }) => (
    //     <>
    //       <p>{row.original.flight_no}</p>
    //     </>
    //   ),
    // },
    {
      Header: "From",
      accessor: "from_location",
      Cell: ({ row }) => (
        <>
          <p>{row.original.from_location}</p>
        </>
      ),
    },
    {
      Header: "To",
      accessor: "to_location",
      Cell: ({ row }) => (
        <>
          <p>{row.original.to_location}</p>
        </>
      ),
    },
    {
      Header: "Checkin Date",
      accessor: (row) => {
        return [`${row.checkin_date}`, `${row.checkout_date}`];
      },
      Cell: ({ row }) => (
        <>
          <p>{row.original.checkin_date}</p>
        </>
      ),
    },
    {
      Header: "Checkin Time",
      accessor: (row) => {
        return [`${row.checkin_time}`, `${row.checkout_time}`];
      },
      Cell: ({ row }) => (
        <>
          <p>{row.original.checkin_time}</p>
        </>
      ),
    },
    {
      Header: "Layover",
      accessor: "layover",
      Cell: ({ row }) => (
        <>
          <p>{row.original.layover}</p>
        </>
      ),
    },
    {
      Header: "Check In Link",
      accessor: "checkin_link",
      Cell: ({ row }) => (
        <>
          <p
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => (window.location = `${row.original.checkin_link}`)}
          >
            {/* <span title={row.original.checkin_link}>{displayedText}</span> */}
            <TruncatedText text={row.original.checkin_link} maxLength={20} />
           
          </p>
        </>
      ),
    },
    // {
    //   Header: "PNR Number",
    //   accessor: "pnr_no",
    //   Cell: ({ row }) => (
    //     <>
    //       <p>{row.original.pnr_no}</p>
    //     </>
    //   ),
    // },
    {
      Header: "Action",
      accessor: "modifiedtime",
      Cell: ({ row }) => (
        <>
          <Flight_Details row_details={row.original} />
        </>
      ),
    },
  ];
  const columns = useMemo(() => COLUMNS, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="main-container">
      <MainSidebar />
      <div className="allcontent">
        <Navbar />
        {/* <Breadcrumbs/> */}
        <div className="mainbox">
          {/* pop up for Create Event event*/}
          {Create_event_modal && (
            <div className="modal">
              <div
                onClick={() => {
                  setCreate_event_modal(!Create_event_modal);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2>Create Flight</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setCreate_event_modal(!Create_event_modal);
                    }}
                  >
                    <AiOutlineClose />
                  </button>
                </div>
                <div style={{ minHeight: "380px" }} className="modal-body">
                  <form onSubmit={handleSubmit}>
                    <div className="input-block">
                      <label htmlFor="select_event" className="input-label">
                        Select Event <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="select_event"
                        id="select_event"
                        value={values.select_event}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="">Select Event</option>
                        {flight_data.EVENT_TABLE_DATA.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.events_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {errors.select_event && touched.select_event ? (
                        <p className="form-error">{errors.select_event}</p>
                      ) : null}
                    </div>

                    <div className="input-block">
                      <label
                        htmlFor="select_flight_type"
                        className="input-label"
                      >
                        Flight Type <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="custominput selectoption"
                        name="select_flight_type"
                        id="select_flight_type"
                        value={values.select_flight_type}
                        // onClick={ ()=> {setDeisgnation(!deisgnation)}}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option disabled value="">
                          Select Flight Type
                        </option>
                        <option value="To Event">
                          To Event Journey
                        </option>
                        <option value="From Event">Return Journey</option>
                      </select>
                      {errors.select_flight_type &&
                      touched.select_flight_type ? (
                        <p className="form-error">
                          {errors.select_flight_type}
                        </p>
                      ) : null}
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="flight_name" className="input-label">
                          Flight Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="flight_name"
                          id="flight_name"
                          placeholder="Flight Name"
                          value={values.flight_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.flight_name && errors.flight_name ? (
                          <p className="form-error">{errors.flight_name}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="flight_number" className="input-label">
                          Flight Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="flight_number"
                          id="flight_number"
                          placeholder="Flight Number"
                          value={values.flight_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.flight_number && errors.flight_number ? (
                          <p className="form-error">{errors.flight_number}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="from_location" className="input-label">
                          From location <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="from_location"
                          id="from_location"
                          placeholder="From location"
                          value={values.from_location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.from_location && touched.from_location ? (
                          <p className="form-error">{errors.from_location}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="to_location" className="input-label">
                          To location <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="to_location"
                          id="to_location"
                          placeholder="To location"
                          value={values.to_location}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.to_location && touched.to_location ? (
                          <p className="form-error">{errors.to_location}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="journey_date" className="input-label">
                          Checkin Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          className="custominput"
                          autoComplete="off"
                          name="journey_date"
                          id="journey_date"
                          placeholder="Journey Date"
                          value={values.journey_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.journey_date && touched.journey_date ? (
                          <p className="form-error">{errors.journey_date}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="checkout_date" className="input-label">
                          Checkout Date
                        </label>
                        <input
                          type="date"
                          className="custominput"
                          autoComplete="off"
                          name="checkout_date"
                          id="checkout_date"
                          placeholder="Journey Time"
                          value={values.checkout_date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.checkout_date && touched.checkout_date ? (
                          <p className="form-error">{errors.checkout_date}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="journey_time" className="input-label">
                          Checkin Time <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="time"
                          className="custominput"
                          autoComplete="off"
                          name="journey_time"
                          id="journey_time"
                          placeholder="Journey Time"
                          value={values.journey_time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.journey_time && touched.journey_time ? (
                          <p className="form-error">{errors.journey_time}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="checkout_time" className="input-label">
                          Checkout Time
                        </label>
                        <input
                          type="time"
                          className="custominput"
                          autoComplete="off"
                          name="checkout_time"
                          id="checkout_time"
                          placeholder="Journey Time"
                          value={values.checkout_time}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.checkout_time && touched.checkout_time ? (
                          <p className="form-error">{errors.checkout_time}</p>
                        ) : null}
                      </div>
                    </div>

                    <div className="input_box">
                      <div
                        className="input-block"
                        style={{ marginRight: "10px" }}
                      >
                        <label htmlFor="layover" className="input-label">
                          Layover
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="layover"
                          id="layover"
                          placeholder="Layover"
                          value={values.layover}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.layover && touched.layover ? (
                          <p className="form-error">{errors.layover}</p>
                        ) : null}
                      </div>
                      <div className="input-block">
                        <label htmlFor="pnr_no" className="input-label">
                          PNR No <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="pnr_no"
                          id="pnr_no"
                          placeholder="Check in Link"
                          value={values.pnr_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.pnr_no && touched.pnr_no ? (
                          <p className="form-error">{errors.pnr_no}</p>
                        ) : null}
                      </div>
                    </div>

                    {/* check in link  */}
                    <div className="input_box">
                      <div className="input-block">
                        <label htmlFor="check_in_link" className="input-label">
                          Check in Link <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="name"
                          className="custominput"
                          autoComplete="off"
                          name="check_in_link"
                          id="check_in_link"
                          placeholder="Check in Link"
                          value={values.check_in_link}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.check_in_link && touched.check_in_link ? (
                          <p className="form-error">{errors.check_in_link}</p>
                        ) : null}
                      </div>
                    </div>
                    {/* check in link  */}
                    <div className="input-block">
                      <label
                        htmlFor="flight_desicption"
                        className="input-label"
                      >
                        Desicption
                      </label>
                      <textarea
                        className="custominput"
                        name="flight_desicption"
                        value={values.flight_desicption}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Desicption"
                      />
                    </div>
                    <div className="modal-buttons">
                      <button className="cusbtn" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {/* pop up for upload file*/}
          {uploadfile && (
            <div className="modal">
              <div
                onClick={() => {
                  setUploadfile(!uploadfile);
                }}
                className="overlay"
              ></div>
              <div className="modal-content">
                <div className="modal-header">
                  <h2> Flight Upload (Bulk)</h2>
                  <button
                    className="close-modal"
                    onClick={() => {
                      setUploadfile(!uploadfile);
                    }}
                  >
                    <CgCloseR />
                  </button>
                </div>
                <div className="modal-body">
                  {/* <form  > */}

                  <div className="input-block">
                    <label htmlFor="select_event" className="input-label">
                      Select Event <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="custominput selectoption"
                      name="select_event"
                      id="select_event"
                      onChange={(e) => {
                        setSelect_event_upload(e.target.value);
                      }}
                    >
                      <option value="">Select Event</option>
                      {flight_data.EVENT_TABLE_DATA
                        ? flight_data.EVENT_TABLE_DATA.map((ele, index) => {
                            return (
                              <>
                                <option index={index} value={ele.id}>
                                  {ele.events_name}
                                </option>
                              </>
                            );
                          })
                        : ""}
                    </select>
                  </div>

                  <div className="download mt-2">
                    <a
                      href={flight_details}
                      download="Flight_sample.csv"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="cusbtn">
                        Download sample.csv file
                      </button>
                    </a>
                  </div>

                  <div className="input-block">
                    <label htmlFor="uploademailfile" className="input-label">
                      Upload Flight Details
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="setuploadfile">
                      <div className="app">
                        <div className="parent">
                          <div className="file-upload">
                            {selectedFile ? (
                              <img
                                style={{ height: "45px" }}
                                src={csv}
                                alt="upload"
                              />
                            ) : (
                              <img
                                className="after_file_upload"
                                src={uploadImg}
                                alt="upload"
                              />
                            )}
                            {selectedFile ? (
                              <h3>{selectedFile.name}</h3>
                            ) : (
                              <h3>Click box to upload</h3>
                            )}
                            <input
                              type="file"
                              style={{ color: "var(--Whiteblack-color)" }}
                              name="file"
                              onChange={(e) => {
                                setSelectedFile(e.target.files[0]);
                              }}
                              accept=".csv"
                              ref={fileInputRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-buttons">
                    <button onClick={submitfile} className="cusbtn">
                      Submit
                    </button>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          )}
          {/* pop up for upload file*/}

          {/* pop up for Create Event event*/}
          {data ? (
            <div className="card">
              <div
                className="department tablebox"
                style={{ backgroundcolor: "white" }}
              >
                {/* pagination */}
                <div className="pages">
                  <div className="tablebottomboxleft">
                    <h1>Show</h1>
                    <span>
                      <select
                        className="selectnoofpagescolor"
                        style={{
                          padding: "4px 10px 4px 3px",
                          borderRadius: "10px",
                        }}
                        value={pageSize}
                        onChange={(e) => setPageSize(Number(e.target.value))}
                      >
                        {[10, 15, 20].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </select>
                    </span>
                  </div>

                  <div style={{ display: "flex" }}>
                    <button
                      style={{
                        marginRight: "5px",
                      }}
                      onClick={() => {
                        setUploadfile(!uploadfile);
                      }}
                      className="cusbtn"
                    >
                      <BiUpload
                        size="1.5em"
                        style={{ verticalAlign: "middle" }}
                      />{" "}
                      Bulk Flight Upload
                    </button>

                    <button
                      onClick={() => {
                        setCreate_event_modal(!Create_event_modal);
                      }}
                      className="cusbtn"
                    >
                      <BiPlusMedical size="1.2rem" />
                    </button>
                  </div>
                </div>
                {/* pagination */}
                <table className="customertable" {...getTableProps()}>
                  <thead>
                    {headerGroups.map((headerGroups) => (
                      <tr {...headerGroups.getHeaderGroupProps()}>
                        {headerGroups.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? "🔽"
                                  : "🔼"
                                : ""}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {/* {flight_data.FLIGHT_TABLE_DATA ? (
                      <> */}
                        {page.length === 0 ? (
                          <tr>
                            <td>No data</td>
                          </tr>
                        ) : (
                          page.map((row) => {
                            prepareRow(row);
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td
                                      style={{
                                        textalign: "center",
                                      }}
                                      {...cell.getCellProps()}
                                    >
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })
                        )}
                      {/* </>
                    ) : (
                      ""
                    )} */}
                  </tbody>
                </table>

                <div className="pagination">
                  <span>
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>{" "}
                  </span>
                  <button
                    className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    <BiLeftArrow />
                  </button>
                  <button
                    className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    <BiRightArrow />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Flight;
