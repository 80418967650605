import React from "react";
import { useNavigate } from "react-router-dom";
import login from "../../components/photos/login.jpg";
import logo from "../../components/photos/wizsuite.png";
import dot from "../../components/photos/dotimg.png";
import { useFormik } from "formik";
import * as Yup from "yup";

const Otp = () => {
  const navigate = useNavigate();

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "276px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
  };

  const initialValues = {
    email: "",
  };

  const signUpSchema = Yup.object({
    otp: Yup.string()
      .email("Must be a valid email")
      .required("This is a required field"),
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
        alert("form Submitted");
        action.resetForm();
      },
    });

  return (
    <>
    <div className="loginbg">
        <div className="loginbox">
        <div className="image">
          {/* <img src={login} alt="" /> */}
          <div></div>
        </div>
        <div style={style} className="form">
          {/* <div className="logindetail"> */}
          <img className="loginlogo" src={logo} alt="" />
          <div className="formbox">
            <h1 className="loginhead">Enter OTP</h1>
            <p>OTP is shared on your registered Email </p>

            <div style={{marginTop:"20px"}}>
              <form onSubmit={handleSubmit}>
              <div className="input-block inputotp">
                  <input
                    className="custominputotp"
                    type="text"
                    name="otp"
                    id="otp"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="text"
                    name="otp"
                    id="otp"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="text"
                    name="otp"
                    id="otp"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="text"
                    name="otp"
                    id="otp"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                </div>
                <div >
                  <button 
                   onClick={() => {
                    navigate("/LoginScreens/Newpassword");
                  }}
                    type="submit"
                    className="cusbtnlogo"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* </div> */}
          </div>

          <p className="bottomlinetext">
          <span
          onClick={() => {
            navigate("/");
          }}
           style={{color: "var(--primary-color)", fontWeight: "bold", cursor: "pointer"}}> Back to Login</span> </p>

          {/* <button
            onClick={() => {
              navigate("/Dashboard/Dashboard.jsx");
            }}
          >
            Go to
          </button> */}
        </div>
        </div>
      </div>
      <div className="login">
        <div className="image">
          {/* <img src={login} alt="" /> */}
          <div></div>
        </div>
        <div style={style} className="form">
          {/* <div className="logindetail"> */}
          <img className="loginlogo" src={logo} alt="" />
          <div>
            <h1 className="loginheading">OTP</h1>

            <div>
              <form  onSubmit={handleSubmit}>
                <div className="input-block inputotp">
                  <input
                    className="custominputotp"
                    type="number"
                    name="email"
                    id="name"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="number"
                    name="email"
                    id="name"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="number"
                    name="email"
                    id="name"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="number"
                    name="email"
                    id="name"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                   <input
                    className="custominputotp"
                    type="number"
                    name="email"
                    id="name"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <input
                    className="custominputotp"
                    type="number"
                    name="email"
                    id="name"
                    // value={values.email}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                </div>

        
                <div>
                  <button
                    type="submit"
                    className="cusbtnlogo"
                    //   style={{ float: "right" }}
                  >
                    Submit Otp
                  </button>
                </div>
              </form>
            </div>
            {/* </div> */}
          </div>

          {/* <button
            onClick={() => {
              navigate("/Dashboard/Dashboard.jsx");
            }}
          >
            Go to
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Otp;
