import { legacy_createStore as createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { applyMiddleware } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
// import { rootReducer } from "./RootReducer";
import { AsyncStorage } from "AsyncStorage";
import Reducer from "./bulkbtns/Reducer";

// const persistConfig =
//     {key: 'root',
//     storage:AsyncStorage,
//     whiteList: ['']
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)
// const store = createStore(
//     persistedReducer,
//     applyMiddleware(thunk)
// );

// export default store;
// export const persistor = persistStore(store);

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  timeout: null,
};

const rootReducer = combineReducers({
    Reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer,applyMiddleware(thunk));
const persit = persistStore(store);
export  { store, persit };
