import React from "react";
import { useNavigate } from "react-router-dom";
import login from "../../components/photos/login.jpg";
import logo from "../../components/photos/wizsuite.png";
import dot from "../../components/photos/dotimg.png";
import { useFormik } from "formik";
import { BiUser } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import * as Yup from "yup";

const Forgetpass = () => {
  const navigate = useNavigate();

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "276px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
  };

  const initialValues = {
    email: "",
  };

  const signUpSchema = Yup.object({
    email: Yup.string()
      .email("Must be a valid email")
      .required("This is a required field"),
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
        alert("form Submitted");
        action.resetForm();
      },
    });

  return (
    <>
    <div className="loginbg">
        <div className="loginbox">
        <div className="image">
          {/* <img src={login} alt="" /> */}
          <div></div>
        </div>
        <div style={style} className="form">
          {/* <div className="logindetail"> */}
          <img className="loginlogo" src={logo} alt="" />
          <div className="formbox">
            <h1 className="loginhead">Forget Password</h1>
            <p>Please provide your registered email to get OTP</p>

            <div style={{marginTop:"20px"}}>
              <form onSubmit={handleSubmit}>
                <div className="input-block">
                  <label className="loginlabel">Email</label>
                  <div className="custominputlogo">
                    <BiUser/>
                    <input
                      type="text"
                      // autoComplete='off'
                      name="email"
                      id="name"
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div style={{ height: "20px", textAlign: "left" }}>
                    {errors.email && touched.email ? (
                      <p className="form-error">{errors.email}</p>
                    ) : null}
                  </div>
                </div>

                <div className="forgetpass">
                  <div className="rememberme">
                    <input type="checkbox" />
                    <p>Remember Password</p>
                  </div>
                </div>
                <div >
                  <button 
                   onClick={() => {
                    navigate("/LoginScreens/Otp");
                  }}
                    type="submit"
                    className="cusbtnlogo"
                  >
                    Send OTP
                  </button>
                </div>
              </form>
            </div>
            {/* </div> */}
          </div>

          <p className="bottomlinetext">
          <span
          onClick={() => {
            navigate("/");
          }}
           style={{color: "var(--primary-color)", fontWeight: "bold", cursor: "pointer"}}> Back to Login</span> </p>

          {/* <button
            onClick={() => {
              navigate("/Dashboard/Dashboard.jsx");
            }}
          >
            Go to
          </button> */}
        </div>
        </div>
      </div>
    </>
  );
};

export default Forgetpass;
