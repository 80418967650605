export const SET_SIDE_MENU_OPEN_CLOSE = 'SET_SIDE_MENU_OPEN_CLOSE';
export const SET_LIGHT_DARK_MODE =  'SET_LIGHT_DARK_MODE';
export const SET_CHECKED_MODE =  'SET_CHECKED_MODE';
export const SET_FLIGHT_TABLE =  'SET_FLIGHT_TABLE';
export const SET_EVENT_TABLE =  'SET_EVENT_TABLE';
export const SET_GUEST_TABLE =  'SET_GUEST_TABLE';
export const SET_UPCOMING_GUEST_TABLE =  'SET_UPCOMING_GUEST_TABLE';
export const SET_OLD_GUEST_TABLE =  'SET_OLD_GUEST_TABLE';
export const SET_ASSISTANCE_TABLE =  'SET_ASSISTANCE_TABLE';
export const SET_CAB_TABLE =  'SET_CAB_TABLE';
export const SET_ACTIVE_LINK =  'SET_ACTIVE_LINK';



