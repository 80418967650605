import React from 'react'

const Dashboardcardbox = (props) => {
    return (
        <div className="card eventcard">
           <div>
            <h1 style={{fontSize: '30px'}}>{props.item.eventnumber}</h1>
            <p>{props.item.eventtitel}</p>
           </div>
           <div>
            <div className='eventicon'>{props.item.eventicon}</div>
           </div>
        </div>
    )
}

export default Dashboardcardbox;
