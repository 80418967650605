import React, { useMemo, useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { BiLeftArrow } from "react-icons/bi";
import { BiRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormData from "form-data";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MOCK_DATA from "./Customers.json";
import { BiPlusMedical } from "react-icons/bi";
import dp from "../../components/photos/dp.jpg";
import Assign from "./Assign";
import { CgCloseR } from "react-icons/cg";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { SETEVENTAPI } from "../../redux/bulkbtns/Actions";

const Upcoming_Event = (props) => {

    const navigate = useNavigate();

    // useEffect
    const dispatch = useDispatch();
  
    const flight_data = useSelector((state) => state.Reducer);
    // console.log("state table= ", props.table);
    // console.log("state = ", props.data);
  
    useEffect(() => {
    
    }, [flight_data.UPCOMING_GUEST_TABLE_DATA,props.data]);

      const data = useMemo(() => { 
        if(props.data){
          return flight_data.UPCOMING_GUEST_TABLE_DATA;
        }
        else{
          return [];
        }
      }
        , [props.data,flight_data.UPCOMING_GUEST_TABLE_DATA]);
    
    // useEffect
  
    // Create Event Popup submit Start
    const signUpSchema = Yup.object().shape({
      eimage: Yup.mixed()
        .required("Select Image")
        .test("fileSize", "Max Size 2MB", (file) => {
          if (file) {
            return file.size <= 2000000;
          } else {
            return true;
          }
        }),
      ename: Yup.string().min(1).required("Enter Event Name"),
      select_assistance: Yup.array().required("Select Assistance"),
      startdate: Yup.date().required("Enter Start Date"),
      enddate: Yup.date().required("Enter End Date"),
      starttime: Yup.string().required("Enter Start Time"),
      endtime: Yup.string().required("Enter End Time"),
      venuename: Yup.string().min(1).required("Enter Venue Name"),
      venuelink: Yup.string().min(1).required("Enter Venue Link"),
      vimage: Yup.mixed().test("fileSize", "Max Size 2MB", (file) => {
        if (file) {
          return file.size <= 2000000;
        } else {
          return true;
        }
      }),
      agenda: Yup.mixed()
        .required("Select Image")
        .test("fileSize", "Max Size 2MB", (file) => {
          if (file) {
            return file.size <= 2000000;
          } else {
            return true;
          }
        }),
      vaddress: Yup.string().min(1).required("Enter Venue Address"),
    });
  
    const [initialValues, setinitialValues] = useState({
      eimage: "",
      select_assistance: "",
      ename: "",
      startdate: "",
      enddate: "",
      starttime: "",
      endtime: "",
      edesicption: "",
      venuename: "",
      venuelink: "",
      vimage: "",
      agenda: "",
      latitude: "",
      longitude: "",
      vaddress: "",
    });
  
    const {
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      errors,
      touched,
    } = useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
        event_form(values);
        // alert("form Submitted");
        // action.resetForm();
      },
    });
    // event api
    async function event_form(e) {
      console.log("select ass =", e.select_assistance);
      const a = e.select_assistance.join();
      console.log("select ass join=", a);
      var dataa = new FormData();
      dataa.append("event", e.eimage);
      dataa.append("select_assistance[]", a);
      dataa.append("venue", e.vimage);
      dataa.append("agenda", e.agenda);
      dataa.append("events_name", e.ename);
      dataa.append("description", e.edesicption);
      dataa.append("event_start_time", e.starttime);
      dataa.append("events_start_date", e.startdate);
      dataa.append("event_end_time", e.endtime);
      dataa.append("events_end_date", e.enddate);
      dataa.append("venue_name", e.venuename);
      dataa.append("venue_link", e.venuelink);
      dataa.append("latitude", e.latitude);
      dataa.append("longitude", e.longitude);
      dataa.append("venue_address", e.vaddress);
      // console.log(dataa);
      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/events.php", dataa)
          .then((response) => {
            console.log(response);
            if (response.data.status == true) {
              alert("Data uploaded successfully");
            } else {
              alert("File not upload");
            }
          });
      } catch (e) {
        console.log(e);
        alert("File not upload");
      }
    }
    // event api
  
    const COLUMNS = [
      {
        Header: "Event Image",
        accessor: "event_image",
        Cell: ({ row }) => (
          <>
            <div
            onClick={()=> {navigate("/Guest/Guest_event_detail",{
              state: {
                name: row.original,
              },
            })}}
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginLeft: "33px",
                cursor:"pointer"
              }}
            >
              <img
               
                src={row.original.event_image}
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  margin: "0px 5px 0px 5px",
                }}
                alt=""
              />
            </div>
          </>
        ),
      },
      {
        Header: "Event Name",
        accessor: "events_name",
  
        Cell: ({ row }) => (
          <>
            <p  
            style={{
              cursor:"pointer"
            }}
            onClick={()=> {navigate("/Guest/Guest_event_detail",{
              state: {
                name: row.original,
              },
            })}}>
              {row.original.events_name}</p>
          </>
        ),
      },
      {
        Header: "Date",
        accessor: (row) => {
          return [`${row.events_start_date}`, `${row.events_end_date}`];
        },
        Cell: ({ row }) => (
          <>
            <p
            style={{
              cursor:"pointer"
            }}
            onClick={()=> {navigate("/Guest/Guest_event_detail",{
              state: {
                name: row.original,
              },
            })}}>
              {row.original.events_start_date} to {row.original.events_end_date}
            </p>
          </>
        ),
      },
      {
        Header: "Time",
        accessor: (row) => {
          return [`${row.event_start_time}`, `${row.event_end_time}`];
        },
        Cell: ({ row }) => (
          <>
            <p>
              {row.original.event_start_time} to {row.original.event_end_time}
            </p>
          </>
        ),
      },
      {
        Header: "Event Description",
        accessor: "description",
        Cell: ({ row }) => (
          <>
            <p>{row.original.description}</p>
          </>
        ),
      }
    ];
  
    const columns = useMemo(() => COLUMNS, []);
    // const data = useMemo(() => MOCK_DATA, []);
  
    const {
      getTableProps,
      gotoPage,
      selectedFlatRows,
      pageCount,
      setPageSize,
      getTableBodyProps,
      pageOptions,
      state,
      setGlobalFilter,
      headerGroups,
      page,
      nextPage,
      previousPage,
      canNextPage,
      canPreviousPage,
      prepareRow,
    } = useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect
    );
  
    const { globalFilter, pageIndex, pageSize } = state;
  return (
    <div>
            <div
              className="department tablebox"
              style={{ backgroundcolor: "white" }}
            >
     

              <table className="customertable" {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroups) => (
                    <tr {...headerGroups.getHeaderGroupProps()}>
                      {headerGroups.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? "🔽"
                                : "🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                {
                   props.data ?
                  <tbody {...getTableBodyProps()}>
                  {
                    page.length===0 ? 
                    <tr>
                      <td>No data</td>
                    </tr>
                  
                  : 
                  page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              style={{
                                textalign: "center",
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody> 
                :""}
                
                
              </table>
              <div className="pagination">
                <span>
                  Page{" "}
                  <strong>
                    {pageIndex + 1} of {pageOptions.length}
                  </strong>{" "}
                </span>
                <button
                  className={!canPreviousPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  <BiLeftArrow />
                </button>
                <button
                  className={!canNextPage ? "nonactivebtn" : "prvisbtn"}
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  <BiRightArrow />
                </button>
              </div>
            </div>
          </div>
  )
}

export default Upcoming_Event
