import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SETGUESTAPI } from "../../../redux/bulkbtns/Actions";
import MOCK_DATA from "./Customers.json";
import dp from "../../../components/photos/dp.jpg";
import Three from "./Three";

const Invoice = () => {
  const navigate = useNavigate();

  // useEffect
  const dispatch = useDispatch();
        
  const flight_data = useSelector((state) => state.Reducer);
 

  const [upcoming, setUpcoming] = useState(false);
  useEffect(() => {
    guest_table_detail() 
    if (flight_data.GUEST_TABLE_DATA) {
      setUpcoming(true);
    }
  }, [upcoming]);

     // guest table api
     async function guest_table_detail() {
      try {
        await axios
          .get("https://xaapps.com/cromptonAPI/get_guest.php")
          .then((response) => {
            if (response.data.status == true) {
              dispatch(SETGUESTAPI(response.data.data));
            } else {
              dispatch(SETGUESTAPI([]));
            }
            // setFlight_table(response.data.data);
          });
      } catch (e) {
        dispatch(SETGUESTAPI([]));
        console.log(e);
      }
    }
     // guest table api

  const data = useMemo(() => { 
    if(upcoming){
     
      return flight_data.GUEST_TABLE_DATA;
    }
    else{
      return [];
    }
  }
    , [upcoming,flight_data.GUEST_TABLE_DATA]);

  // guest table api
  // useEffect

  const COLUMNS = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <>
          <p
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/Guest/Guest_Details", {
                state: {
                  name: row.original,
                },
              });
            }}
          >
            {row.original.name}
          </p>
        </>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
      Cell: ({ row }) => (
        <>
          <p>{row.original.phone}</p>
        </>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ row }) => (
        <>
          <p>{row.original.email}</p>
        </>
      ),
    },
    {
      Header: "City",
      accessor: "city",
      Cell: ({ row }) => (
        <>
          <p>{row.original.city}</p>
        </>
      ),
    },
    {
      Header: "T-Shirt",
      accessor: "t_shirt",
      Cell: ({ row }) => (
        <>
          <p>{row.original.t_shirt}</p>
        </>
      ),
    },
    {
      Header: "Govt ID",
      accessor: "govermt_id_type",
      Cell: ({ row }) => (
        <>
          <p>{row.original.govermt_id_type}</p>
        </>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);

  const {
    getTableProps,
    gotoPage,
    selectedFlatRows,
    pageCount,
    setPageSize,
    getTableBodyProps,
    pageOptions,
    state,
    setGlobalFilter,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div
        className="department1 tablebox1"
        style={{ backgroundcolor: "white", overflow: "hidden" }}
      >
        {/* pagination */}
        <div className="pages"></div>
        {/* pagination */}

        <table className="customertable" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroups) => (
              <tr {...headerGroups.getHeaderGroupProps()}>
                {headerGroups.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? "🔽"
                          : "🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {flight_data.GUEST_TABLE_DATA ?<tbody {...getTableBodyProps()}>
            
                  {
                    page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                style={{
                                  textalign: "center",
                                }}
                                {...cell.getCellProps()}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                
            
           
          </tbody>: 
            <tbody {...getTableBodyProps()}>
              <tr>
                <td>No data</td>
              </tr>
             </tbody>
            } 
        </table>
      </div>
    </>
  );
};

export default Invoice;
