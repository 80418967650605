import React, { useState } from "react";
import { CgCloseR } from "react-icons/cg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { HiPencilAlt } from "react-icons/hi";
import axios from "axios";

const Flight_Details = (props) => {

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  // Create Event Popup submit Start
  const signUpSchema = Yup.object({
    // flight_name_edit: Yup.string().required("Enter Flight Name"),
    // flight_number_Edit: Yup.string().required("Enter Flight Number"),
    // journey_date_Edit: Yup.date().required("Enter Journey Start Date"),
    // checkout_date_Edit: Yup.date().required("Enter Journey End Date"),
    // journey_time_Edit: Yup.string().required("Enter Journey Start Time"),
    // checkout_time_Edit: Yup.string().required("Enter Journey End Time"),
  });

  const [initialValues, setinitialValues] = useState({
    flight_name_edit: props.row_details.flight_name,
    flight_number_Edit: props.row_details.flight_no,
    journey_date_Edit: props.row_details.checkin_date,
    checkout_date_Edit: props.row_details.checkout_date,
    journey_time_Edit: props.row_details.checkin_time,
    checkout_time_Edit: props.row_details.checkout_time,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: signUpSchema,
    validateOnChange: true,
    validateOnBlur: false,
    //// By disabling validation onChange and onBlur formik will validate on submit.
    onSubmit: (values, action) => {
      // console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
      //// to get rid of all the values after submitting the form
      flightform(values);

      action.resetForm();
    },
  });

  // flight api
  async function flightform(e) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/updateflight.php", {
          id: props.row_details.id,
          checkout_date: e.checkout_date_Edit,
          checkout_time: e.checkout_time_Edit,
          checkin_date: e.journey_date_Edit,
          checkin_time: e.journey_time_Edit,
          flight_name: e.flight_name_edit,
          flight_no: e.flight_number_Edit,
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            alert("Data uploaded successfully");
          } else {
            alert(response.data.message);
          }
        });
    } catch (e) {
      console.log(e);
    }
  }
  // flight api

  return (
    <>
      <button
        className="cusbtn"
        onClick={toggleModal}
        style={{ display: "flex", alignItems: "center" }}
      >
        <HiPencilAlt />
        Edit
      </button>
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Edit Flight</h2>
              <button className="close-modal" onClick={toggleModal}>
                <CgCloseR />
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input_box">
                  <div className="input-block" style={{ marginRight: "10px" }}>
                    <label htmlFor="flight_name_edit" className="input-label">
                      Flight Name 
                    </label>
                    <input
                      type="name"
                      className="custominput"
                      autoComplete="off"
                      name="flight_name_edit"
                      id="flight_name_edit"
                      placeholder="Flight Name"
                      value={values.flight_name_edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.flight_name_edit && errors.flight_name_edit ? (
                      <p className="form-error">{errors.flight_name_edit}</p>
                    ) : null}
                  </div>
                  <div className="input-block">
                    <label htmlFor="flight_number_Edit" className="input-label">
                      Flight Number 
                    </label>
                    <input
                      type="name"
                      className="custominput"
                      autoComplete="off"
                      name="flight_number_Edit"
                      id="flight_number_Edit"
                      placeholder="Flight Number"
                      value={values.flight_number_Edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.flight_number_Edit && errors.flight_number_Edit ? (
                      <p className="form-error">{errors.flight_number_Edit}</p>
                    ) : null}
                  </div>
                </div>
                <div className="input_box">
                  <div className="input-block" style={{ marginRight: "10px" }}>
                    <label htmlFor="journey_date_Edit" className="input-label">
                      Checkin Date 
                    </label>
                    <input
                      type="date"
                      className="custominput"
                      autoComplete="off"
                      name="journey_date_Edit"
                      id="journey_date_Edit"
                      placeholder="Journey Date"
                      value={values.journey_date_Edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.journey_date_Edit && touched.journey_date_Edit ? (
                      <p className="form-error">{errors.journey_date_Edit}</p>
                    ) : null}
                  </div>
                  <div className="input-block">
                    <label htmlFor="checkout_date_Edit" className="input-label">
                      Checkout Date
                    </label>
                    <input
                      type="date"
                      className="custominput"
                      autoComplete="off"
                      name="checkout_date_Edit"
                      id="checkout_date_Edit"
                      placeholder="Journey Time"
                      value={values.checkout_date_Edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.checkout_date_Edit && touched.checkout_date_Edit ? (
                      <p className="form-error">{errors.checkout_date_Edit}</p>
                    ) : null}
                  </div>
                </div>

                <div className="input_box">
                  <div className="input-block" style={{ marginRight: "10px" }}>
                    <label htmlFor="journey_time_Edit" className="input-label">
                      Checkin Time 
                    </label>
                    <input
                      type="time"
                      className="custominput"
                      autoComplete="off"
                      name="journey_time_Edit"
                      id="journey_time_Edit"
                      placeholder="Journey Time"
                      value={values.journey_time_Edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.journey_time_Edit && touched.journey_time_Edit ? (
                      <p className="form-error">{errors.journey_time_Edit}</p>
                    ) : null}
                  </div>
                  <div className="input-block">
                    <label htmlFor="checkout_time_Edit" className="input-label">
                      Checkout Time
                    </label>
                    <input
                      type="time"
                      className="custominput"
                      autoComplete="off"
                      name="checkout_time_Edit"
                      id="checkout_time_Edit"
                      placeholder="Journey Time"
                      value={values.checkout_time_Edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.checkout_time_Edit && touched.checkout_time_Edit ? (
                      <p className="form-error">{errors.checkout_time_Edit}</p>
                    ) : null}
                  </div>
                </div>

                <div className="modal-buttons">
                  <button className="cusbtn" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Flight_Details;
