import React from "react";
import { useNavigate } from "react-router-dom";
import login from "../../components/photos/login.jpg";
import logo from "../../components/photos/wizsuite.png";
import dot from "../../components/photos/dotimg.png";
import { useFormik } from "formik";
import { BiUser } from "react-icons/bi";
import { RiLockPasswordLine } from "react-icons/ri";
import * as Yup from "yup";

const Login = () => {
  const navigate = useNavigate();

  const style = {
    backgroundImage: `url(${dot})`,
    backgroundSize: "276px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right top",
  };

  const initialValues = {
    newpassword: "",
    confirm_password: "",
  };

  const signUpSchema = Yup.object({
    newpassword: Yup.string()
      .min(2, "This is a required field")
      .required("This is a required field"),

    confirm_password: Yup.string()
    .oneOf([Yup.ref('newpassword'), null], 'Passwords must match')
  });

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,
      //// By disabling validation onChange and onBlur formik will validate on submit.
      onSubmit: (values, action) => {
        console.log("🚀 ~ file: App.jsx ~ line 17 ~ App ~ values", values);
        //// to get rid of all the values after submitting the form
        alert("form Submitted");
        action.resetForm();
      },
    });

  return (
    <>
        <div className="loginbg">
          <div className="loginbox">
          <div className="image">
            <div></div>
          </div>
          <div style={style} className="form">
            <img className="loginlogo" src={logo} alt="" />
            <div className="formbox">
              <h1 className="loginhead">New Password</h1>
              <p>Please set new password</p>

              <div style={{marginTop:"20px"}}>
                <form onSubmit={handleSubmit}>
                <div className="input-block">
                    <label htmlFor="newpassword" className="loginlabel">New Password</label>
                    <div className="custominputlogo">
                      <RiLockPasswordLine/>
                    <input
                      type="password"
                      
                      name="newpassword"
                      id="newpassword"
                      placeholder="New Password"
                      value={values.newpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.newpassword && touched.newpassword ? (
                        <p className="form-error">{errors.newpassword}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="input-block">
                    <label htmlFor="confirm_password" className="loginlabel">Confirm Password</label>
                    <div className="custominputlogo">
                      <RiLockPasswordLine/>
                    <input
                      type="password"
                      
                      name="confirm_password"
                      id="confirm_password"
                      placeholder="Confirm Password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    </div>
                    <div style={{ height: "20px", textAlign: "left" }}>
                      {errors.confirm_password && touched.confirm_password ? (
                        <p className="form-error">{errors.confirm_password}</p>
                      ) : null}
                    </div>
                  </div>
                  <div >
                    <button
                      type="submit"
                      className="cusbtnlogo"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          </div>
        </div>
    </>
  );
};

export default Login;
