import * as openclose from "./Constants.jsx";

const initialState={
    SIDEMENUOPENCLOSE:false,
    LIGHTDARKMODE:"light-theme",
    CHECKEDDARKMODE: false,
    FLIGHT_TABLE_DATA: "",
    EVENT_TABLE_DATA: "",
    GUEST_TABLE_DATA: "",
    ASSISTANCE_TABLE_DATA: "",
    CAB_TABLE_DATA: "",
    INCOMING_FLIGHT_DROPDOWN: "",
    UPCOMING_GUEST_TABLE_DATA: "",
    OLD_GUEST_TABLE_DATA: "",
    VALUE_ACTIVE_LINK: 1,
 
}

export default (state = initialState , action) => {
    switch(action.type) {
        case openclose.SET_SIDE_MENU_OPEN_CLOSE: 
        return {...state,SIDEMENUOPENCLOSE: action.payload };

        case openclose.SET_LIGHT_DARK_MODE: 
        return {...state,LIGHTDARKMODE: action.payload };

        case openclose.SET_CHECKED_MODE: 
        return {...state,CHECKEDDARKMODE: action.payload };

        case openclose.SET_FLIGHT_TABLE: 
        return {...state,FLIGHT_TABLE_DATA: action.payload };

        case openclose.SET_EVENT_TABLE: 
        return {...state,EVENT_TABLE_DATA: action.payload };

        case openclose.SET_GUEST_TABLE: 
        return {...state,GUEST_TABLE_DATA: action.payload };

        case openclose.SET_ASSISTANCE_TABLE: 
        return {...state,ASSISTANCE_TABLE_DATA: action.payload };

        case openclose.SET_CAB_TABLE: 
        return {...state,CAB_TABLE_DATA: action.payload };

        case openclose.SET_UPCOMING_GUEST_TABLE: 
        return {...state,UPCOMING_GUEST_TABLE_DATA: action.payload };

        case openclose.SET_OLD_GUEST_TABLE: 
        return {...state,OLD_GUEST_TABLE_DATA: action.payload };

        case openclose.SET_ACTIVE_LINK: 
        return {...state,VALUE_ACTIVE_LINK: action.payload };

        default : return state;
    }
    
}