import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { CgCloseR } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
// import { SETINCOMINGFLIGHTS } from "../../redux/bulkbtns/Actions";
// import { SETEVENTIDGUEST } from "../../redux/bulkbtns/Actions";

const Assign = (props) => {
  const [modal, setModal] = useState(false);
  // console.log("user",props.userid)

  const [event_id, setevent_id] = useState("");

  const [incoming_Flight, setincoming_Flight] = useState("");
  const [assign_incoming_flight, set_assign_incoming_flight] = useState("");

  const [outgoing_Flight, setoutgoing_Flight] = useState("");
  const [assign_outgoing_flight, set_assign_outgoing_flight] = useState("");

  const [incoming_cab, setincoming_cab] = useState("");
  const [assign_incoming_cab, set_assign_incoming_cab] = useState("");

  const [outgoing_cab, setoutgoing_cab] = useState("");
  const [assign_outgoing_cab, set_assign_outgoing_cab] = useState("");

  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState();

  const flight_data = useSelector((state) => state.Reducer);

  const [events_for_user, setEvents_for_user] = useState();
  useEffect(() => {
    get_events_for_user()
  }, []);

   // get_events_for_user api
   async function get_events_for_user() {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/showassigneventstouser.php", {
          user_id: props.userid,
        })
        .then((response) => {
          // console.log("get_incomingcab", response.data.data);

          if (response.data.status == true) {
            setEvents_for_user(response.data.data);
          } 
          
        });
    } catch (e) {
      console.log(e);
      
    }
  }
  // get_events_for_user api

  const toggleModal = () => {
    setModal(!modal);
  };

  const dispatch = useDispatch();

  const handleChange = (e) => {
    // console.log("hii", e.target.value);
    setevent_id(e.target.value);
    post_event_id_flight(e.target.value);
    post_event_id_cab(e.target.value);
    post_event_id_assistance(e.target.value);
    // setSelected("");
  };

  // get_assistance api
  async function post_event_id_assistance(a) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/getAssistantFromId.php", {
          events_id: a,
        })
        .then((response) => {
          // console.log("get_assistance", response.data);
          // chnage key name here
          if (response.data.status == true) {
            function selectKeyValuesFromArray(array, keyMapping) {
              const selectedArray = [];

              // Iterate over the array of objects
              for (let i = 0; i < array.length; i++) {
                const originalObject = array[i];
                const selectedObject = {};

                // Create a new object with the desired key-value pairs
                for (const originalKey in keyMapping) {
                  if (originalObject.hasOwnProperty(originalKey)) {
                    const selectedKey = keyMapping[originalKey];
                    selectedObject[selectedKey] = originalObject[originalKey];
                  }
                }

                // Push the selected object into the new array
                selectedArray.push(selectedObject);
              }
              // setOptions(selectedArray)
              return selectedArray;
            }

            const a = selectKeyValuesFromArray(response.data.data, {
              name: "label",
              id: "value",
            });
            setOptions(a);
            console.log("options= ", options);
          } else {
            setOptions("");
          }
        });
    } catch (e) {
      console.log(e);
      setOptions("");
    }
  }
  // get_assistance api
  // get_incomingcab api
  async function post_event_id_cab(a) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/get_incomingcab.php", {
          events_id: a,
        })
        .then((response) => {
          // console.log("get_incomingcab", response.data);
          if (response.data.status == true) {
            // dispatch(SETINCOMINGFLIGHTS(response.data.incoming_Flight));
            setincoming_cab(response.data.from_airport_to_events);
            setoutgoing_cab(response.data.from_event_to_airport);
          } else {
            setincoming_cab("");
            setoutgoing_cab("");
          }
        });
    } catch (e) {
      console.log(e);
      setincoming_cab("");
      setoutgoing_cab("");
    }
  }
  // get_incomingcab api

  // get_incomingflight api
  async function post_event_id_flight(a) {
    try {
      await axios
        .post("https://xaapps.com/cromptonAPI/get_incomingflight.php", {
          events_id: a,
        })
        .then((response) => {
          // console.log("get_incomingflight", response.data);
          if (response.data.status == true) {
            // dispatch(SETINCOMINGFLIGHTS(response.data.incoming_Flight));
            setincoming_Flight(response.data.thailand_jara);
            setoutgoing_Flight(response.data.thailand_se_wapis);
          } else {
            setincoming_Flight("");
            setoutgoing_Flight("");
          }
        });
    } catch (e) {
      console.log(e);
      setincoming_Flight("");
      setoutgoing_Flight("");
    }
  }
  // get_incomingflight api

  // assign multiple submit

  function submit_assign(array, keyMapping) {
    const selectedArray = [];

    // Iterate over the array of objects
    for (let i = 0; i < array.length; i++) {
      const originalObject = array[i];
      const selectedObject = {};

      // Create a new object with the desired key-value pairs
      for (const originalKey in keyMapping) {
        if (originalObject.hasOwnProperty(originalKey)) {
          const selectedKey = keyMapping[originalKey];
          selectedObject[selectedKey] = originalObject[originalKey];
        }
      }

      // Push the selected object into the new array
      selectedArray.push(selectedObject);
    }
    // flight api
    flightassign();
    async function flightassign() {
      const arr = selectedArray.map((object) => object.id);
      const a = arr.join();
      if (event_id == "") {
        alert("Select an Event to get relevent information");
        return;
      }

      if (assign_incoming_flight == "" || assign_incoming_flight == undefined) {
        alert("To Event Journey Flight is Empty");
        return;
      }
      // if (assign_incoming_cab == "" || assign_incoming_cab == undefined) {
      //   alert("To Event Journey Cab is Empty");
      //   return;
      // }
      if (assign_outgoing_flight == "" || assign_outgoing_flight == undefined) {
        alert("Return Journey Flight is Empty");
        return;
      }
      // if (assign_outgoing_cab == "" || assign_outgoing_cab == undefined) {
      //   alert("Return Journey Cab is Empty");
      //   return;
      // }
      if (a == "") {
        alert("Assistance is Empty");
        return;
      }
      try {
        await axios
          .post("https://xaapps.com/cromptonAPI/events_assign.php", {
            events_id: event_id,
            user_id: props.userid,
            flight_in: assign_incoming_flight,
            flight_out: assign_outgoing_flight,
            cab_id_in: assign_incoming_cab,
            cab_id_out: assign_outgoing_cab,
            assistance_team_id: a,
          })
          .then((response) => {
            console.log("submit response assign=", response);
            if (response.data.status == true) {
              alert("Uploaded Successfully");
              setModal(!modal);
              setOptions("");
              // setSelected("");
              setincoming_cab("");
              setoutgoing_cab("");
              setincoming_Flight("");
              setoutgoing_Flight("");
            } else {
              alert(response.data.message);
              setOptions("");
              // setSelected("");
              setincoming_cab("");
              setoutgoing_cab("");
              setincoming_Flight("");
              setoutgoing_Flight("");
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
    // flight api

  }
  // assign multiple submit

  return (
    <>
      {/* pop up for assign */}
      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="modal-header">
              <h2>Assign Event To Guest</h2>
              <button className="close-modal" onClick={toggleModal}>
                <CgCloseR />
              </button>
            </div>
            <div style={{ minHeight: "380px" }} className="modal_body_assign">
              <div className="Assign_multi_to_guest">
                {/* <h1>Select Event</h1> */}
                <div className="input-block">
                  <label htmlFor="select_event" className="input-label">
                    Select Event <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="custominput selectoption"
                    name="select_event"
                    id="select_event"
                    onChange={handleChange}
                  >
                    <option value="">Select Event</option>
                    {events_for_user
                      ? events_for_user.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.events_name}
                              </option>
                            </>
                          );
                        })
                      : ""}
                  </select>
                </div>
              </div>

              <div className="">
                <h1>
                  To Event{" "}
                  <small style={{ color: "red", fontSize: "10px" }}>
                    Journey
                  </small>
                </h1>
                <div className="input-block assign_guest">
                  <label htmlFor="select_event" className="input-label">
                    flight <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="custominput selectoption"
                    name="select_event"
                    id="select_event"
                    value={assign_incoming_flight}
                    onChange={(e) => {
                      set_assign_incoming_flight(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select flight
                    </option>
                    {incoming_Flight
                      ? incoming_Flight.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.flight_name} {ele.flight_no}
                              </option>
                            </>
                          );
                        })
                      : ""}
                  </select>
                  {/* {errors.select_event && touched.select_event ? (
                        <p className="form-error">{errors.select_event}</p>
                      ) : null} */}
                </div>

                <div className="input-block assign_guest">
                  <label htmlFor="select_event" className="input-label">
                    cab <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="custominput selectoption"
                    name="select_event"
                    id="select_event"
                    value={assign_incoming_cab}
                    onChange={(e) => {
                      set_assign_incoming_cab(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select cab
                    </option>
                    {incoming_cab
                      ? incoming_cab.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.pickup_point} {ele.cab_no}
                              </option>
                            </>
                          );
                        })
                      : ""}
                  </select>
                  {/* {errors.select_event && touched.select_event ? (
                        <p className="form-error">{errors.select_event}</p>
                      ) : null} */}
                </div>
              </div>
              <div className="">
                <h1>
                  Return{" "}
                  <small style={{ color: "red", fontSize: "10px" }}>
                    Journey
                  </small>
                </h1>
                <div className="input-block assign_guest">
                  <label htmlFor="select_event" className="input-label">
                    flight <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="custominput selectoption"
                    name="select_event"
                    id="select_event"
                    value={assign_outgoing_flight}
                    onChange={(e) => {
                      set_assign_outgoing_flight(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select flight
                    </option>
                    {outgoing_Flight
                      ? outgoing_Flight.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.flight_name} {ele.flight_no}
                              </option>
                            </>
                          );
                        })
                      : ""}
                  </select>
                  {/* {errors.select_event && touched.select_event ? (
                        <p className="form-error">{errors.select_event}</p>
                      ) : null} */}
                </div>

                <div className="input-block assign_guest">
                  <label htmlFor="select_event" className="input-label">
                    cab <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="custominput selectoption"
                    name="select_event"
                    id="select_event"
                    value={assign_outgoing_cab}
                    onChange={(e) => {
                      set_assign_outgoing_cab(e.target.value);
                    }}
                  >
                    <option disabled value="">
                      Select cab
                    </option>
                    {outgoing_cab
                      ? outgoing_cab.map((ele, index) => {
                          return (
                            <>
                              <option index={index} value={ele.id}>
                                {ele.drop_point} {ele.cab_no}
                              </option>
                            </>
                          );
                        })
                      : ""}
                  </select>
                  {/* {errors.select_event && touched.select_event ? (
                        <p className="form-error">{errors.select_event}</p>
                      ) : null} */}
                </div>
              </div>

              <div
                style={{ marginTop: "20px" }}
                className="Assign_multi_to_guest"
              >
                <label htmlFor="select_event" className="input-label">
                  Select Assistance <span style={{ color: "red" }}>*</span>
                </label>
                {options ? (
                  <div style={{ minHeight: "180px" }} className="modal-body">
                    <MultiSelect
                      options={options}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="assign_submit_btn">
                <button
                  className="cusbtn"
                  onClick={() => {
                    submit_assign(selected, { value: "id" });
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* pop up for assign */}
      <div
        onClick={toggleModal}
        style={{
          display: "inline-flex",
          marginRight: "4px",
          justifyContent: "center",
        }}
      >
        <button className="cusbtn">Assign</button>
      </div>
    </>
  );
};

export default Assign;
