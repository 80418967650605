import React, { useRef, useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { BsSun } from "react-icons/bs";
import { BsMoonStars } from "react-icons/bs";
import { AiFillIdcard } from "react-icons/ai";
import { AiOutlineSetting } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { SetSideMenuOpenClose } from "../redux/bulkbtns/Actions";
import dp from "../components/photos/dp.jpg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SETLIGHTDARKMODE } from "../redux/bulkbtns/Actions";
import { SETCHECKEDMODE } from "../redux/bulkbtns/Actions";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setprofile] = useState(false);
  // theme color 
  const dispatch = useDispatch();

  const navbarlightdark = useSelector((state) => state.Reducer);
  // console.log("light dark mode", navbarlightdark.LIGHTDARKMODE);

  const toggleTheme = () =>{
    if( navbarlightdark.LIGHTDARKMODE === "dark-theme"){
      dispatch(SETLIGHTDARKMODE("light-theme"));
      dispatch(SETCHECKEDMODE(false));
    }
    else{
      dispatch(SETLIGHTDARKMODE("dark-theme"));
      dispatch(SETCHECKEDMODE(true));
    }
  }
  // theme color 


  const dropdown = useRef();
  const outsidedropdown = useRef();

  const navigate = useNavigate();

  useEffect(() => {
    document.body.className = navbarlightdark.LIGHTDARKMODE;
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        profile &&
        outsidedropdown.current &&
        !outsidedropdown.current.contains(e.target)
      ) {
        setprofile(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [profile,navbarlightdark.LIGHTDARKMODE]);

  const toggle = () => {
    setIsOpen(!isOpen);
    dispatch(SetSideMenuOpenClose(isOpen));
  };

  const toggledropdown = () => {
    setprofile(!profile);
  };

  return (
    <>
      <div className="navbar">
        <div className="lefthalfnavbar">
          <div className="navbarsbtn">
            <FaBars onClick={toggle} />
          </div>

          {/* <div className="searchbar">
            <input className="search" placeholder="Search.." />
            <BiSearchAlt />
          </div> */}
          <div className="toggle-theme-wrapper">
            <div className="toggleicons"><BsSun/></div>
            <label class="switch">
              <input type="checkbox"
              checked={navbarlightdark.CHECKEDDARKMODE} onClick={() => toggleTheme()} />
              <span class="slider round"></span>
            </label>
            <div className="toggleicons"><BsMoonStars/></div>
          </div>
        </div>

        <div className="righthalfnavbar">
          <div className="dp">
            <img
              src={dp}
              className="indp"
              style={{
                height: "35px",
                width: "35px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
              alt=""
            />
          </div>
          <div ref={outsidedropdown} className="admin" onClick={toggledropdown}>
            <p>Admin</p>
            <MdKeyboardArrowDown className="navbardownarrow" />
            {profile && (
              <div ref={dropdown} className="dropdown">
                <div onClick={toggledropdown} className="pageicon">
                  <p
                    onClick={() => {
                      navigate("/AdminProfile/Profile");
                    }}
                  >
                    <AiFillIdcard /> Profile{" "}
                  </p>
                </div>
                <div onClick={toggledropdown} className="pageicon">
                  <p>
                    <AiOutlineSetting /> Setting{" "}
                  </p>
                </div>
                <div onClick={toggledropdown} className="pageicon">
                  <p>
                    <FiLogOut /> Log out{" "}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
