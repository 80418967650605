import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { BiTimeFive } from "react-icons/bi";
import Dashboardcardbox from "./Dashboardcardbox";
import { BiCalendarEvent } from "react-icons/bi";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { BsCalendar2Date } from "react-icons/bs";
import { MdNotificationsActive } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { BiLocationPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Invoice from "./invoicetable/Invoice";
import MainSidebar from "../../components/Sidebar/MainSidebar";
import { useDispatch } from "react-redux";
import { SETEVENTAPI } from "../../redux/bulkbtns/Actions";
import { SETFLIGHTAPI } from "../../redux/bulkbtns/Actions";
import axios from "axios";
import { SETGUESTAPI } from "../../redux/bulkbtns/Actions";
import { SETASSISTANCEAPI } from "../../redux/bulkbtns/Actions";
import { SETCABAPI } from "../../redux/bulkbtns/Actions";
import { useSelector } from "react-redux";
import event_img from "../../components/photos/dp.jpg";

const Dashboard = () => {
  const navigate = useNavigate();

  const [ totalregistration, setTotalregistration] = useState("");
  const [ total_upcoming_event, set_total_upcoming_events] = useState("");
  const [ total_upcoming_eventtable, set_total_upcoming_eventtable] = useState("");
  useEffect(() => {
    
    total_registration();
    total_upcoming_events();
    upcoming_events_table();
    event_table_detail()
    assistance_table_detail()
    flight_table_detail() 
    cab_table_detail() 
    guest_table_detail()
  }, [totalregistration]);

  const dispatch = useDispatch();
  const flight_data = useSelector((state) => state.Reducer);

   // guest table api
   async function guest_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_guest.php")
        .then((response) => {
          if (response.data.status == true) {
            dispatch(SETGUESTAPI(response.data.data));
          } else {
            dispatch(SETGUESTAPI([]));
          }
          // setFlight_table(response.data.data);
        });
    } catch (e) {
      dispatch(SETGUESTAPI([]));
      console.log(e);
    }
  }
   // guest table api

    // cab table api
    async function cab_table_detail() {
      try {
        await axios
          .get("https://xaapps.com/cromptonAPI/get_cab.php")
          .then((response) => {
            if(response.data.status == true){
            dispatch(SETCABAPI(response.data.data));
          }
          else{
              dispatch(SETCABAPI([]));
              
            }
           
          });
        } catch (e) {
        dispatch(SETCABAPI([]));
        console.log(e);
      }
    }
      // cab table api

  // flight table api
  async function flight_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_flight.php")
        .then((response) => {
          // console.log(response.data.data);
          if (response.data.status == true) {
            dispatch(SETFLIGHTAPI(response.data.data));
          } else {
            dispatch(SETFLIGHTAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETFLIGHTAPI([]));
      console.log(e);
    }
  }
   // flight table api

   // event table api
   async function event_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_events.php")
        .then((response) => {
          // console.log(response)
          if (response.data.status == true) {
            dispatch(SETEVENTAPI(response.data.data));
          } else {
            dispatch(SETEVENTAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETEVENTAPI([]));

      console.log(e);
    }
  }

  // event table api

   // assistance table api
   async function assistance_table_detail() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/get_assistance.php")
        .then((response) => {
          if (response.data.status == true) {
            dispatch(SETASSISTANCEAPI(response.data.data));
          } else {
            dispatch(SETASSISTANCEAPI([]));
          }
        });
    } catch (e) {
      dispatch(SETASSISTANCEAPI([]));
      console.log(e);
    }
  }
  // assistance table api

    // TOTTAL UPCOMING events api
  
    async function upcoming_events_table() {
      try {
        await axios
          .get("https://xaapps.com/cromptonAPI/upcomingeventstable.php")
          .then((response) => {
           
            set_total_upcoming_eventtable(response.data.data)
          
          });
        } catch (e) {
          console.log(e);
        }
      }
  
    // TOTTAL UPCOMING events api

    // TOTTAL UPCOMING events api
  
    async function total_upcoming_events() {
      try {
        await axios
          .get("https://xaapps.com/cromptonAPI/totalupcomingevents.php")
          .then((response) => {
           
            set_total_upcoming_events(response.data.total_guests)
          
          });
        } catch (e) {
          console.log(e);
        }
      }
  
    // TOTTAL UPCOMING events api

   // total_registration api
  
   async function total_registration() {
    try {
      await axios
        .get("https://xaapps.com/cromptonAPI/totaluser.php")
        .then((response) => {
         
            setTotalregistration(response.data.total_users)
        
        });
      } catch (e) {
        console.log(e);
      }
    }
   
  // total_registration api

  const dashboardcards = [
    {
      eventicon: (
        <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
      ),
      eventtitel: "Total Registeration",
      eventnumber: totalregistration,
    },
    {
      eventicon: (
        <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
      ),
      eventtitel: "Total Speakers",
      eventnumber: "357+",
    },
    {
      eventicon: (
        <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
      ),
      eventtitel: "Active Users",
      eventnumber: "26",
    },
    {
      eventicon: (
        <FaUsers size="2em" style={{ color: "var(--eventicon-color)" }} />
      ),
      eventtitel: "New Event",
      eventnumber: total_upcoming_event,
    },
  ];

  const upcomingevent = [
    {
      eventname: "Digital Business Summit-2023",
      date: "3 august 2023",
      time: "9:00am - 5:00pm",
      location: "California(CA),92677",
    },
    {
      eventname: "Digital Business Summit-2023",
      date: "3 august 2023",
      time: "9:00am - 5:00pm",
      location: "California(CA),92677",
    },
    {
      eventname: "Digital Business Summit-2023",
      date: "3 august 2023",
      time: "9:00am - 5:00pm",
      location: "California(CA),92677",
    },
    {
      eventname: "Digital Business Summit-2023",
      date: "3 august 2023",
      time: "9:00am - 5:00pm",
      location: "California(CA),92677",
    },
    {
      eventname: "Digital Business Summit-2023",
      date: "3 august 2023",
      time: "9:00am - 5:00pm",
      location: "California(CA),92677",
    },
    {
      eventname: "Digital Business Summit-2023",
      date: "3 august 2023",
      time: "9:00am - 5:00pm",
      location: "California(CA),92677",
    },
  ];

  // rescharts test

  return (
    <>
    <div className="main-container">
      <MainSidebar />
      <div className="allcontent">
        <Navbar />
        <div className="mainbox">
          <div className="dash_event_cards">
            {dashboardcards.map((ele, index) => {
              return <Dashboardcardbox index={index} item={ele} />;
            })}
          </div>

          <div className="event_notify_dashboard mt-2">
            <div className="event_notification card">
              <div className="cardheading">
                <BiCalendarEvent />
                <h1>Upcoming Events</h1>
              </div>
              <div className="deventdatabox">
                { total_upcoming_eventtable?total_upcoming_eventtable.map((ele, index) => {
                  return (
                    <>
                      <div className="upcoming_event" key={index}>
                        <div className="up_event_img">
                          <img src={ele.event_image} alt="" />
                        </div>
                        <div className="up_event_content">
                          <h1>{ele.events_name}</h1>
                          <div className="meta_eventbox">
                            <div className="up_event_meta">
                              <BsCalendar2Date />
                              <p>{ele.events_start_date}</p>
                            </div>
                            <div className="up_event_meta">
                              <BiTimeFive />
                              <p>{ele.event_start_time}</p>
                            </div>
                            <div className="up_event_meta">
                              <BiLocationPlus />
                              <p>{ele.venue_name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }):"NO Events"}
              </div>
            </div>
            <div className="event_notification card">
              <div className="cardheading">
                <MdOutlineNotificationsNone />
                <h1>Notification</h1>
              </div>
              <div className="deventdatabox">
                {upcomingevent.map((ele, index) => {
                  return (
                    <>
                      <div className="upcoming_event" key={index}>
                        <div className="notifi_icon">
                          <MdNotificationsActive />
                        </div>
                        <div className="up_event_content">
                          <h1>{ele.eventname}</h1>
                          <p>
                            Lorem ipsum dolor, sit amet consectetur adipisicing
                            elit. Ducimus consequuntur laborum neque dolorem
                            iste tempore quasi ab itaque autem
                          </p>
                          <p style={{ fontSize: "12px", textAlign: "right" }}>
                            may2 2023, 5:00pm
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="tablesec mt-2">
            <div className="tableboxdash">
              <div className="card try1">
                <div className="salesanalytics tableheading">
                  <h1>Guest List</h1>
                  <button
                    onClick={() => {
                      navigate("/Guest/Guest.jsx");
                    }}
                    className="cusbtn"
                  >
                    View All
                  </button>
                </div>
                <Invoice />
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </div>
    </>
  );
};

export default Dashboard;
